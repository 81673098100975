import React from "react";
import {Link} from "react-router-dom";

const MegaMenu = () => {
    return (
        <ul className="navbar-nav">
            <li className="nav-item">
                <Link className="nav-link" to="/">
                    Головна
                </Link>
                {/* /.dropdown-menu */}
            </li>
            {/* End li */}

            <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="/service" data-toggle="dropdown">
                    Послуги
                </Link>

                <ul className="dropdown-menu">
                    <li className="dropdown-submenu dropdown">
                        <Link
                            to="/service-details"
                            className="dropdown-item"
                        >
                            Автоматизація
                        </Link>
                    </li>
                    <li className="dropdown-submenu dropdown">
                        <Link
                            to="/service-audit"
                            className="dropdown-item">
                            Аудит
                        </Link>
                    </li>
                    <li>
                        <Link to="/service-learning" className="dropdown-item">Навчання</Link>
                    </li>
                    <li>
                        <Link to="/service-implement" className="dropdown-item">Впровадження</Link>
                    </li>
                    <li>
                        <Link to="/service-consult" className="dropdown-item">Консалтинг</Link>
                    </li>
                    <li>
                        <Link to="/support" className="dropdown-item">Технічна підтримка</Link>
                    </li>
                </ul>
                {/* /.dropdown-menu */}
            </li>
            {/* End li */}
            <li className="nav-item">
                <Link to="/creatio" className="nav-link">
                    Creatio
                </Link>
                {/* /.dropdown-menu */}
            </li>
            <li className="nav-item">
                <Link to="/portfolio" className="nav-link">
                    Портфоліо
                </Link>
                {/* /.dropdown-menu */}
            </li>
            <li className="nav-item">
                <Link to="/blog" className="nav-link">
                    Блог
                </Link>
                {/* /.dropdown-menu */}
            </li>

            <li className="nav-item dropdown">
                <Link to="/about" className="nav-link dropdown-toggle" data-toggle="dropdown">
                    Про нас
                </Link>

                <ul className="dropdown-menu">
                    <li className="dropdown-submenu dropdown">
                        <Link
                            to="/partner"
                            className="dropdown-item">
                            Партнери
                        </Link>
                    </li>
                </ul>
            </li>

            <li className="nav-item">
                <Link to="/faq" className="nav-link">
                    Хз
                </Link>
                {/* /.dropdown-menu */}
            </li>
            <li className="nav-item">
                <Link to="/contact" className="nav-link">
                    Контакти
                </Link>
                {/* /.dropdown-menu */}
            </li>

        </ul>
    );
    // End navbar nav mega menu main
};

export default MegaMenu;
