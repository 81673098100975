import React from "react";
import { Link } from "react-router-dom";
import Clutch from "../widgets/clutch";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/crmgenesis",
  },
  {
    icon: "fa-instagram",
    link: "https://www.instagram.com/crm_genesis/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/company/crm-genesis/",
  },
];

const FooterTwo = () => {

  return (
    <div className="row justify-content-between">
      <div
        className="col-lg-2 col-12 footer-about-widget"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="/" className="logo">
          <img src="/images/logo/main-logo.png" alt="brand" />
        </a>

        <Clutch />
        <img src="/images/genesis/champion_badge_2023.png" className="mt-4"/>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="footer-title">Послуги</h5>
        <ul>
          <li>
            <Link to="/service-details">Автоматизація</Link>
          </li>
          <li>
            <Link to="/service-audit">Аудит бізнес-процесів</Link>
          </li>
          <li>
            <Link to="/service-learning">Навчання</Link>
          </li>
          <li>
            <Link to="/service-implement">Впровадження</Link>
          </li>
          <li>
            <Link to="/service-consult">Консалтинг</Link>
          </li>
          <li>
            <Link to="/support">Технічна підтримка</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-2 col-md-3 col-sm-6 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <h5 className="footer-title">Про нас</h5>
        <ul>
          <li>
            <Link to="/about">Про компанію</Link>
          </li>
          <li>
            <Link to="/portfolio">Портфоліо</Link>
          </li>
          <li>
            <Link to="/partner">Партнери</Link>
          </li>
          <li>
            <Link to="/contact">Контакти</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-3 col-sm-6 address-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="200"
      >
        <h5 className="footer-title">Контакти</h5>
        <ul className="info">
          <li>
            вул. Амвросія Бучми 5, офіс 109, Київ, Україна
          </li>
          <li>
            <a href="Tel:0442000422" className="pt-30 mobile-num">
              (044) 200 04 22
            </a>
          </li>
        </ul>
        <ul className="social-icon d-flex">
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* /.footer-list */}
    </div>
    //.row
  );
};

export default FooterTwo;
