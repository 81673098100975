import React from "react";

const PricingContent = [
  {
    packName: "Starter",
    price: "18000",
    packageFor: "15 годин/ 2 користувача",
    icon: "38",
    percent: "0",
    billCycle: "Вартість години вище ліміту 1320 грн",
    preFeatures: [
      "Пріоритет: високий, звичайний, низький, нові роботи ",
    ],
  },
  {
    packName: "Team",
    price: "34200",
    packageFor: "30 годин/ 3 користувача",
    icon: "40",
    percent: "5",
    billCycle: "Вартість години вище ліміту 1260 грн",
    preFeatures: [
      "Пріоритет: високий, звичайний, низький, нові роботи ",
    ],
  },
  {
    packName: "Business",
    price: "64800",
    packageFor: "60 годин/ 5 користувачів",
    icon: "41",
    percent: "10",
    billCycle: "Вартість години вище ліміту 1200 грн",
    preFeatures: [
      "Пріоритет: високий, звичайний, низький, нові роботи ",
    ],
  },
];

const PricingMonthly = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div className="col-lg-4 col-md-6" key={i}>
          <div className="pr-table-wrapper">
            <div className="pack-name">{val.packName}</div>
            <div className="price font-rubik">
              {val.price}<sup>грн</sup>
            </div>
            <div className="pack-rec font-rubik">{val.packageFor}</div>
            <img src={`images/icon/${val.icon}.svg`} alt="icon" className="icon" />
            <ul className="pr-feature">
              {val.preFeatures.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>

            <div className="trial-text font-rubik">
              {val.billCycle}
            </div>
            <div className="trial-text font-rubik">
              Знижка {val.percent}%
            </div>
            <a href="/contact" className="theme-btn-four mt-2">
              Оберіть тариф
            </a>
          </div>
          {/*  /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

export default PricingMonthly;
