import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import HeaderThree from "../../../components/header/HeaderThree";
import FaqClassicThree from "../../../components/faq/FaqClassicThree";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import ServiceBlock from "../../../components/service/ServiceBlock";
import ServiceRandomNote from "../../../components/service/ServiceRandomNote";
import MainModal from "../../../components/modals";

const FaqContent = [
    {
        title: "Які CRM-системи ви впроваджуєте?",
        desc: `Ми пропонуємо нашим клієнтам міжнародну українську платформу Creatio. Платформа має дуже потужний і  широкий функціонал, входить в топ-5-10 кращих платформ у світі за різними рейтингами. Наші фахівці добре знайомі з іншими продуктами, представленими на ринку, але, по-перше ми обираємо працювати з найкращими, а по-друге - глибока експертиза досягається тоді, коли працюєш з одним продуктом і не розпилюєш свої знання, увагу та зусилля.`,
        expand: "b",
    },
    {
        title: "Як нашій компанії навчити всіх співробітників працювати в новій CRM-системі?",
        desc: `Ми маємо комплексний навчальний план, що забезпечує ефективне навчання всіх співробітників у використанні нової CRM-системи, максимальний практичний ефект, та підтримку після запуску. Ми не можемо гарантувати відсутність саботажу співробітників (бо це залежить від багатьох факторів), але за 10 років роботи в цій сфері ми розробили такі методики, які знижують потенційний саботаж до мінімуму.`,
        expand: "c",
    },
    {
        title: "Чи можна розібратися в інтерфейсі Creatio без вашої допомоги?",
        desc: `Система Creatio позиціонується як No-Code платформа, яка не потребує спеціалізованих знань та навичок для її використання. Але наші фахівці навчать Вас набагато швидше та ефективніше, і Ви не будете набивати шишки в процесі навчання. Наш комплексний підхід економить Ваш час та гроші.`,
        expand: "d",
    },
    {
        title: "Навіщо моїй компанії CRM-система?",
        desc: `CRM-система допоможе вам ефективніше управляти взаєминами з клієнтами, покращити продуктивність вашого відділу продажів та збільшити дохідність вашого бізнесу. Завдяки CRM-системі ви зможете централізовано зберігати інформацію про клієнтів, стежити за їхніми потребами та історією взаємодії з компанією. Ви зможете налагоджувати персоналізовані комунікації, маркетинг, прогнозувати та аналізувати продажі, ефективно розподіляти ресурси та багато іншого. CRM-система стане Вашим надійним помічником у веденні бізнесу та допоможе Вам забезпечити високу задоволеність клієнтів, розширити вашу клієнтську базу та збільшити прибуток компанії. Основна задача CRM-системи -  приймати рішення на основі даних, а не суджень та емоцій.`,
        expand: "f",
    },
];


const ServiceLearning = () => {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModalOne() {
        setIsOpen(!isOpen);
    }
    return (
        <div className="main-page-wrapper p0">
            <Helmet>
                <title>Практичне навчання користуванню СRM - системою | CRM Genesis</title>
                <meta
                    property="og:title"
                    content="Практичне навчання користуванню СRM - системою"
                />
                <meta
                    name="description"
                    content="Зрозуміле та структуроване навчання користуванню CRM - системою.
                Підготовка Ваших співробітників до роботи - покажемо на практиці як працювати в crm-системі"
                />
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderTwo/>
            {/* End Header */}

            {/* =============================================
            Fancy Hero One
        ==============================================  */}
            <div className="fancy-hero-five">
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="text-center row">
                            <div className="col-xl-9 m-auto">
                                <h1 className="heading">Навчання користуванню CRM-системою</h1>
                                <p className="sub-heading space-xs  mt-15">Навчимо, як працює система, та як впровадити
                                    її у свій бізнес - структуровано, швидко, зрозуміло, практично</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.bg-wrapper --> */}
            </div>
            {/* <!-- /.fancy-hero-five --> */}

            {/* =============================================
            Service Details One
        ==============================================  */}
            <div className="service-details-one pt-150 md-pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-8 order-lg-last">
                            <div className="details-wrapper pl-xl-5">
                                <h2 className="fs-4">
                                    Зекономимо Ваш час та гроші на навчання, спираючись на наш досвід
                                </h2>
                                <p className="sub-heading  pt-30 pb-70 md-pb-40">
                                    Ми проведемо навчання в CRM для Ваших співробітників. Співпраця з нашою командою
                                    дозволить Вам звільнити більше часу для стратегічного планування та розвитку вашого
                                    бізнесу.
                                </p>
                                <img
                                    src="/images/genesis/розділ%20навчання.png"
                                    alt="gallery"
                                    className="img-meta w-100"
                                />

                                <h4 className="pt-30 pb-30">На навчанні Ваші співробітники дізнаються:</h4>

                                <ul className="list-item-one">
                                    <li>базові можливості системи Creatio</li>
                                    <li>як використовувати систему Creatio самостійно у Вашій компанії</li>
                                    <li>як наповнювати та працювати з базою клієнтів</li>
                                    <li>які функції системи дозволяють приймати правильні рішення та підвищувати
                                        конверсію без збільшення витрат
                                    </li>
                                    <li>як досягти максимальних результатів за допомогою CRM-системи</li>
                                </ul>

                                <p className="pt-40 pb-40">Ми спростимо Вашу адаптацію до нової системи до мінімуму.</p>

                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className=" pt-40">Наша мета:</h4>
                                        <ul className="list-item-one">
                                            <li>Підвищення навичок</li>
                                            <li>Ефективне використання CRM</li>
                                            <li>Зрозуміле, продуктивне та практичне навчання</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className=" pt-40">Очікуваний результат:</h4>
                                        <p>
                                            Ваші співробітники розбираются у функціоналі системи Creatio та вміють нею
                                            користуватись.
                                        </p>
                                    </div>
                                </div>
                                {/* End .row */}

                                <h3 className=" pt-90 pb-60 md-pt-50">
                                    Питання та відповіді
                                </h3>
                                <FaqClassicThree FaqContent={FaqContent}/>
                            </div>
                            {/* <!-- /.details-wrapper --> */}
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 order-lg-first">
                            <div className="sidebar md-mt-100">
                                <ServiceBlock/>
                                <ServiceRandomNote/>
                            </div>
                            {/* <!-- /.sidebar --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /.service-details-one --> */}

            {/*
      =============================================
            Footer
        ============================================== */}
            <div className="footer-bg-wrapper">
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="fancy-short-banner-two">
                    <div className="container">
                        <div className="content-wrapper">
                            <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                                <h2 className="font-gilroy-bold">
                                    Навчимо користуванню CRM-системою
                                </h2>
                                <Link onClick={() => setIsOpen(true)}> Звʼязатись з нами </Link>
                                <MainModal title="Навчимо користуванню CRM-системою" isOpen={isOpen} setIsOpen={setIsOpen}/>
                                <div className="bubble-one"></div>
                                <div className="bubble-two"></div>
                                <div className="bubble-three"></div>
                                <div className="bubble-four"></div>
                                <div className="bubble-five"></div>
                            </div>
                            {/* /.bg-wrapper */}
                        </div>
                        {/*  /.content-wrapper */}
                    </div>
                    {/* /.container */}
                </div>
                {/* /.fancy-short-banner-two */}

                <footer className="theme-footer-two pt-150 md-pt-80">
                    <div className="top-footer">
                        <div className="container">
                            <FooterTwo/>
                        </div>
                        {/* /.container */}
                    </div>
                    {/* /.top-footer */}

                    <div className="container">
                        <div className="bottom-footer-content">
                            <CopyRightTwo/>
                        </div>
                        {/*  /.bottom-footer */}
                    </div>
                </footer>
                {/* /.theme-footer-one */}
            </div>
        </div>
    );
};

export default ServiceLearning;
