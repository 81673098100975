import React from "react";

const PricingContent = [
    {
        packName: "Високий",
        preFeatures: [
            "2 години",
            "24 години",
            "2Х",
            "2,5Х",
            "0 годин",
            "0,33 годин",
            "8.00-20.00 пн-пт\n10.00-17.00 сб",
        ],
    },
    {
        packName: "Звичайний",
        preFeatures: [
            "4 години",
            "72 години",
            "1Х",
            "1,25Х",
            "0 годин",
            "0,33 годин",
            "9.00-18.00 пн-пт"
        ],
    },
    {
        packName: "Низький",
        preFeatures: [
            "8 години",
            "168 години",
            "0,8Х",
            "1,1Х",
            "0 годин",
            "0,33 годин",
            "9.00-18.00 пн-пт"
        ],
    },
    {
        packName: "Нові роботи",
        preFeatures: [
            "4 години",
            "згідно з оцінкою",
            "1Х",
            "1,25Х",
            "0 годин",
            "0,33 годин",
            "9.00-18.00 пн-пт"
        ],
    },
];

const PricingMonthly = () => {
    return (
        <div className="row no-gutters">
            <div className="col-lg-4 col-sm-6 pr-bg d-flex" >
                <div className="pr-table-wrapper">
                    <div className="pack-name">Пріоритет/Тип задачі</div>


                    <ul className="pr-feature">
                        <li>Час реакції на звернення</li>
                        <li>Максимальний термін виконання*</li>
                        <li>Коефіцієнт витрат передплачених годин при виконанні задачі аналітиком</li>
                        <li>Коефіцієнт витрат передплачених годин при виконанні задачі програмістом</li>
                        <li>Оцінка робіт (при умові наступного виконання робіт), годин</li>
                        <li>Оцінка робіт (при відмові від роботи в цьому місяці), годин</li>
                        <li>Години роботи</li>
                    </ul>
                </div>
                {/* /.pr-table-wrapper  */}
            </div>
            {PricingContent.map((val, i) => (
                <div className="col-lg-2 col-sm-6 pr-bg d-flex" key={i}>
                    <div className="pr-table-wrapper">
                        <div className="pack-name">{val.packName}</div>
                        <ul className="pr-feature text-center pl-0">
                            {val.preFeatures.map((list, i) => (
                                <li key={i}>{list}</li>
                            ))}
                        </ul>
                    </div>
                    {/* /.pr-table-wrapper  */}
                </div>
            ))}
        </div>
    );
};

export default PricingMonthly;
