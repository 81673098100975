import React from "react";
import {random} from "lodash";

const data = [
    "Автоматизація бізнес-процесів — більша продуктивність, менше помилок.",
    "Оцінка та оптимізація бізнес-процесів підвищить якість Вашого бізнесу.",
    "Практичне навчання — ефективне користування CRM-системою.",
    "Використайте потужність CRM-системи для оптимізації Вашої клієнтської взаємодії та досягнення усіх цілей Вашого бізнесу.",
    "Ми допоможемо Вам автоматизувати процеси, щоб Ви мали більше часу та ресурсів для зростання.",
    "Наша команда забезпечить повну підтримку та професійний підхід до Вашого проєкту."
];

const ServiceRandomNote = () => {
    const randomIndex = random(0, data.length - 1);
    const randomNote = data[randomIndex];

    return (
        <div className="sidenote">
            <p>
                {randomNote}
            </p>
        </div>
    );
}

export default ServiceRandomNote
