import React from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import Social from "../../../components/social/Social";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import AboutFour from "../../../components/about/AboutFour";
import CallToActionThree from "../../../components/call-to-action/CallToActionThree";

const ProductCustomerSupport = () => {
    return (
        <div className="main-page-wrapper p0">
            <Helmet>
                <title>Creatio - єдина платформа з широким функціоналом</title>
                <meta
                    property="og:title"
                    content="Creatio - єдина платформа з широким функціоналом"
                />
                <meta
                    name="description"
                    content="Creatio - єдине no-code середовище для автоматизації процесів будь-якої складності. Готові рішення для різніх галузей, омніканальність, інтеграція застосунків або пристроїв, глибока аналітика."
                />
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderTwo/>
            {/* End Header */}

            {/* 	=============================================
				Fancy Hero Three
			============================================== */}
            <div className="fancy-hero-three bg-transparent">
                <div className="shapes shape-one"></div>
                <div className="shapes shape-two"></div>
                <div className="shapes shape-three"></div>
                <div className="shapes shape-four"></div>
                <div className="shapes shape-five"></div>
                <div className="shapes shape-six"></div>
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                                <h1 className="heading">
                                    Creatio - єдина платформа
                                </h1>
                                <p className="sub-heading">
                                    для автоматизації процесів та CRM за допомогою no-code технологій
                                </p>
                                <a
                                    href="https://www.creatio.com/ua/?partner=CrmGenesis"
                                    target="_blank"
                                    className="theme-btn-two trial-button mt-50 md-mt-30">
                                    {" "}
                                    ТЕСТ-ДРАЙВ
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.bg-wrapper  */}
            </div>
            {/* /.fancy-hero-three */}

            {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
            <div className="fancy-feature-seven">
                <div className="container">
                    <div className="title-style-two text-center mb-150 md-mb-70">
                        <p>Єдина платформа</p>
                        <h2>
                            Що пропонує
                            <span> Creatio:{" "}
                                <img src="images/shape/line-shape-8.svg" alt="line shape"/>
                            </span>
                        </h2>
                    </div>
                    {/* /.title-style-two */}

                    <div className="block-wrapper">
                        <div className="block-style-nine">
                            <div className="row align-items-center">
                                <div
                                    className="col-lg-7 col-md-9 m-auto"
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                >
                                    <div className="illustration-holder">
                                        <img src="/images/creatio/img1.png" alt="illustrator"/>
                                    </div>
                                    {/* /.illustration-holder */}
                                </div>
                                <div
                                    className="col-lg-5"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper">


                                        <h3 className="title font-gilroy-bold">
                                            Знати потреби аудиторії та знаходити підхід до кожного клієнта
                                        </h3>
                                        <div className="service-details-one">
                                            <div className="details-wrapper">
                                                <ul className="list-item-one">
                                                    <li>Розгорнутий профіль клієнта</li>
                                                    <li>Робота з контактами та контрагентами</li>
                                                    <li>Гнучка динамічна сегментація</li>
                                                    <li>Інструменти імпорту даних та злиття дублів</li>
                                                    <li>Збагачення даних із відкритих джерел</li>
                                                    <li>Повна історія взаємовідносин</li>
                                                    <li>Аналітика у різних зрізах</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div>
                        {/* /.block-style-nine */}
                        <div className="block-style-nine my-4">
                            <div className="row align-items-center">
                                <div
                                    className="col-lg-7 col-md-9 m-auto order-lg-last"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <div className="illustration-holder">
                                        <img src="/images/creatio/img2.jpg" alt="illustrator"/>
                                    </div>
                                    {" "}
                                    {/* /.illustration-holder */}
                                </div>
                                <div
                                    className="col-lg-5 order-lg-first"
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper">
                                        <h3 className="title font-gilroy-bold">
                                            Автоматизація процесів будь-якої складності
                                        </h3>
                                        <div className="service-details-one">
                                            <div className="details-wrapper">
                                                <ul className="list-item-one">
                                                    <li>Дизайнер бізнес-процесів з підтримкою нотації BPMN2</li>
                                                    <li>Технології Case Management</li>
                                                    <li>Інструменти для налаштування логіки роботи процесу:
                                                        від планування
                                                        активностей до управління колекціями даних у процесах</li>
                                                    <li>AI-помічник для швидкої побудови діаграм</li>
                                                    <li>Потужне ядро процесів</li>
                                                    <li>Інструменти для моніторингу та аналізу процесів</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    {" "}
                                    {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div>
                        {" "}
                        {/* /.block-style-nine */}
                        <div className="block-style-nine my-4">
                            <div className="row align-items-center">
                                <div
                                    className="col-lg-7 col-md-9 m-auto"
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                >
                                    <div className="illustration-holder">
                                        <img src="/images/creatio/img3.png" alt="illustrator"/>
                                    </div>
                                    {" "}
                                    {/* /.illustration-holder */}
                                </div>
                                <div
                                    className="col-lg-5"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper">
                                        <h3 className="title font-gilroy-bold">
                                            Управління даними незалежно від масштабу організації
                                        </h3>
                                        <div className="service-details-one">
                                            <div className="details-wrapper">
                                                <ul className="list-item-one">
                                                    <li>Готові дашборди в кожному розділі системи</li>
                                                    <li>Різноманітні варіанти відображення даних: зведені
                                                        таблиці, графіки,
                                                        діаграми, показники та ін. </li>
                                                    <li>Функція drill down для аналізу вихідних даних</li>
                                                    <li>Інструменти план-фактного аналізу показників </li>
                                                    <li>Користувацькі no-code інструменти для налаштування
                                                        звітів та дашбордів</li>
                                                    <li>Інтеграція з BI-системами для глибокого аналізу
                                                        даних</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {" "}
                                    {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div>
                        {" "}
                        {/* /.block-style-nine */}
                        <div className="block-style-nine my-4">
                            <div className="row align-items-center">
                                <div
                                    className="col-lg-7 col-md-9 m-auto order-lg-last"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <div className="illustration-holder">
                                        <img src="/images/creatio/img7.jpg" alt=""/>
                                    </div>
                                    {/* /.illustration-holder */}
                                </div>
                                <div
                                    className="col-lg-5 order-lg-first"
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper">
                                        <h3 className="title font-gilroy-bold">
                                            Інтеграція застосунків або пристроїв
                                        </h3>
                                        <div className="service-details-one">
                                            <div className="details-wrapper">
                                                <ul className="list-item-one">
                                                    <li>Мобільний доступ до ключових функцій та даних Creatio: розклад, дашборди,
                                                        клієнтські дані, продажі, візування документів, звернення та ін.</li>
                                                    <li>No-code інструменти для кастомізації мобільного
                                                        застосунку</li>
                                                    <li>Готові конфігурації застосунків (наприклад, для
                                                        польових продавців або
                                                        користувачів порталу самообслуговування)</li>
                                                    <li>Фонова синхронізація даних із основною системою</li>
                                                    <li>Онлайн- та офлайн-режими роботи</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {" "}
                                    {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div>
                        <div className="block-style-nine my-4">
                            <div className="row align-items-center">
                                <div
                                    className="col-lg-7 col-md-9 m-auto"
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                >
                                    <div className="illustration-holder">
                                        <img src="/images/creatio/img6.png" alt="illustrator"/>
                                    </div>
                                    {" "}
                                    {/* /.illustration-holder */}
                                </div>
                                <div
                                    className="col-lg-5"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper">
                                        <h3 className="title font-gilroy-bold">
                                            Глибока аналітика, посилена BI та ML\AI-сервіси
                                        </h3>
                                        <div className="service-details-one">
                                            <div className="details-wrapper">
                                                <ul className="list-item-one">
                                                    <li>Різноманіття використовуваних AI-технологій: скорингові та рекомендаційні
                                                        моделі, автоматичне заповнення полів та отримання даних з
                                                        email-повідомлень</li>
                                                    <li>Готові ML-моделі для підвищення ефективності
                                                        продажів, маркетингу та сервісу</li>
                                                    <li>Користувацький механізм налаштування предиктивних
                                                        моделей</li>
                                                    <li>Пояснення факторів, що призвели до того чи іншого
                                                        результату прогнозування</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {" "}
                                    {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div>
                        {" "}
                        {/* /.block-style-nine */}
                        <div className="block-style-nine my-4">
                            <div className="row align-items-center">
                                <div
                                    className="col-lg-7 col-md-9 m-auto order-lg-last"
                                    data-aos="fade-left"
                                    data-aos-duration="1200"
                                >
                                    <div className="illustration-holder">
                                        <img src="/images/creatio/img5.png" alt=""/>
                                    </div>
                                    {/* /.illustration-holder */}
                                </div>
                                <div
                                    className="col-lg-5 order-lg-first"
                                    data-aos="fade-right"
                                    data-aos-duration="1200"
                                >
                                    <div className="text-wrapper">
                                        <h3 className="title font-gilroy-bold">
                                            Єдине NO-CODE середовище для аналітиків і розробників
                                        </h3>
                                        <div className="service-details-one">
                                            <div className="details-wrapper">
                                                <ul className="list-item-one">
                                                    <li>Єдиний фреймворк для швидкої розробки застосунків за допомогою no-code
                                                        інструментів</li>
                                                    <li>Можливість користувацькими засобами налаштовувати
                                                        інтерфейсні сторінки, моделі даних, процеси та інтеграції</li>
                                                    <li>Доступ до розширених інструментів конфігурування для
                                                        професійних розробників</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {" "}
                                    {/* /.text-wrapper */}
                                </div>
                            </div>
                        </div>
                        {/* /.block-style-nine */}
                    </div>

                </div>
                <div className="container">
                    <div className="row">

                        <div className="col-12 col-lg-3 col-sm-6 m-auto">
                            <a
                                href="https://www.creatio.com/ua/?partner=CrmGenesis"
                                target="_blank"
                                className="theme-btn-two trial-button mt-50 md-mt-30 btn-block text-center">
                                {" "}
                                ТЕСТ-ДРАЙВ
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* /.fancy-feature-seven */}


            {/* 	=====================================================
				Footer Style Two
			===================================================== */}
            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo/>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default ProductCustomerSupport;
