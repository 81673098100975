import React, { useState } from "react";
import ModalVideo from "react-modal-video";

const FancyVideoFive = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 order-lg-last">
          <div className="text-wrapper pt-0">
            <div className="title-style-two mb-35 md-mb-20">
              <h2>
                <span>
                  Послуги{" "}
                  <img src="images/shape/line-shape-5.svg" alt="icon" />
                </span><br />
                з автоматизації бізнесу
              </h2>
            </div>
            {/* <!-- /.title-style-two --> */}
            <p>
              Наші рішення налагодять взаємодію між усіма відділами Вашої компанії,
              впорядкують завдання та проєкти.
            </p>
          </div>
        </div>
        {/* End .col */}

        <div className="col-lg-6 col-md-12 order-lg-first">
          <div className="video-box">
            <img className="w-100" src="images/genesis/img_110.png" alt="media" />
          </div>
          {/* <!-- /.video-box --> */}
        </div>
      </div>
    </>
  );
};

export default FancyVideoFive;
