import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PortfolioContent = [
  {
    img: "6401b40edb92db431d6ecca9_ringostat-logo.svg",
  },
  {
    img: "1-GigaCloud-1.png",
  },
  {
    img: "logo_creatio.svg",
  },
  {
    img: "logo_uk.png",
  },
  {
    img: "logo_ukr_retina.png",
  },
  {
    img: "udc-logo.svg",
  },
];

const Portfolio = () => {
  const settings = {
    dots: true,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: "30",
  };

  return (
    <>

      <Slider {...settings}>
        {PortfolioContent.map((val, i) => (
          <div className="item" key={i}>
            <div className="portfolio-block-three align-items-center p-5" style={{height: '200px'}}>
                <img
                  src={`images/partner/${val.img}`}
                  alt="portfolio"
                  className="tran5s"
                  style={{maxHeight: '100%'}}
                />
            </div>
            {/* /.portfolio-block-three  */}
          </div>
          //  End .item
        ))}
      </Slider>
    </>
  );
};

export default Portfolio;
