import React, {useState} from "react";
import { Helmet } from "react-helmet";
import CallToActionFour from "../../../../components/call-to-action/CallToActionFour";
import Pricing from "../../../../components/pricing/pricing-two/Pricing";
import PricingOne from "../../../../components/pricing/pricing-one/Pricing";

import HeaderTwo from "../../../../components/header/HeaderTwo";
import FooterTwo from "../../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../../components/footer/CopyRightTwo";
import {Link} from "react-router-dom";
import HeaderPopupForm from "../../../../components/form/HeaderPopupForm";
import Modal from "react-modal";
import MainModal from "../../../../components/modals";

const ProjectManagement = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>Технічна підтримка Вашого бізнесу на базі Creatio</title>
        <meta
            property="og:title"
            content="CRM Genesis | Технічна підтримка Вашого бізнесу"
        />
        <meta
            name="description"
            content="CRM Genesis надає надійну технічну підтримку для вашого бізнесу. Оберіть тариф відповідно вашого бюджету та ми
            вирішемо технічні задачі швидко та ефективно."
        />
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}

      {/* =============================================
				Pricing Section One
			============================================== */}
      <div className="pricing-section-one">
        <div className="fancy-hero-one">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-11 m-auto">
                <h2 className="font-rubik">Технічна підтримка CRM на базі Creatio</h2>
              </div>
              <div className="col-12 m-auto">
                <p className="font-rubik">
                  Оберіть тариф, відповідно до ваших потреб та бюджету
                </p>
              </div>
            </div>
          </div>
          <div className="bubble-one"></div>
          <div className="bubble-two"></div>
          <div className="bubble-three"></div>
          <div className="bubble-four"></div>
          <div className="bubble-five"></div>
          <div className="bubble-six"></div>
        </div>
        {/* /.fancy-hero-one */}
        <div className="pricing-table-area">
          <img
            src="images/shape/62.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/63.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <div className="container">
            <div className="tab-content">
              <Pricing />
            </div>
          </div>
          {/* End .container */}
        </div>
      </div>
      {/* /.pricing-section-one */}

      <div className="pricing-section-two pricing-section-two-custom">
        <div className="fancy-hero-four">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <div className="shapes shape-six"></div>
          <div className="bg-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-xl-10 col-lg-11 m-auto">
                  <h2>Ви самі обираєте пріоритет Ваших задач</h2>
                </div>
              </div>
              {/* End .row */}
            </div>
            {/* End .container */}
          </div>
          {/*  /.bg-wrapper */}
        </div>
        {/* /.fancy-hero-four */}

        <div className="container">
          <div className="pricing-table-area">
            <div className="tab-content">
              <PricingOne />
            </div>

            <img
                src="images/shape/64.svg"
                alt="shape"
                className="shapes shape-one"
            />
            <img
                src="images/shape/65.svg"
                alt="shape"
                className="shapes shape-two"
            />
          </div>
          <small className="mt-4" style={{fontSize: 12}}>*якщо це не критична помилка системи Creatio, і вона не потребує додаткового звернення в підтримку
            Creatio</small>
          {/*  /.pricing-table-area  */}
        </div>
      </div>

      <div className="fancy-short-banner-two mt-100">
        <div className="container">
          <div className="content-wrapper">
            <div
                className="
                  bg-wrapper
                  d-lg-flex
                  align-items-center
                  justify-content-between
                "
            >
              <h2 className="font-gilroy-bold">
                Повна підтримка Вашого бізнесу
              </h2>

              <MainModal title="Звʼязатись з нами" isOpen={isOpen} setIsOpen={setIsOpen}/>

              <Link onClick={() => setIsOpen(true)}> Звʼязатись з нами </Link>

              <div className="bubble-one"></div>
              <div className="bubble-two"></div>
              <div className="bubble-three"></div>
              <div className="bubble-four"></div>
              <div className="bubble-five"></div>
            </div>


            {/* /.bg-wrapper */}
          </div>
          {/*  /.content-wrapper */}
        </div>
        {/* /.container */}
      </div>


      {/*
      =============================================
				Footer
		  ============================================== */}
      <footer className="theme-footer-two pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default ProjectManagement;
