import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import HeaderThree from "../../../components/header/HeaderThree";
import FaqClassicThree from "../../../components/faq/FaqClassicThree";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import ServiceBlock from "../../../components/service/ServiceBlock";
import ServiceRandomNote from "../../../components/service/ServiceRandomNote";
import MainModal from "../../../components/modals";

const FaqContent = [
    {
        title: "Чи підійде автоматизація бізнес-процесів для мого бізнесу?",
        desc: `Автоматизація бізнес-процесів може бути корисною для  бізнесу будь-якого розміру та сфери дяільності. Вона дозволяє оптимізувати робочі процеси, зменшити кількість рутинних завдань, підвищити ефективність та точність роботи, знизити витрати, підвищити конверсію, збільшити рівень продажів, та покращити комунікацію з клієнтами. Автоматизація може бути особливо корисною, якщо ваш бізнес має повторювані завдання, широку базу клієнтів або потребує систематичного аналізу даних. Наші фахівці залюбки проконсультують Вас по автоматизації саме вашого бізнесу, та зможуть надати релевантні кейси.`,
        expand: "a",
    },
    {
        title: "Скільки часу займе автоматизація мого бізнесу?",
        desc: `Час, необхідний для автоматизації вашого бізнесу, залежить від різних факторів, таких як кількість співробітників, складність процесів, кількість систем і додатків, які потрібно інтегрувати, та часу, який ви готові виділяти на комунікацію з нашими фахівцями (бо це двосторонній процес, який потребує вашого залучення). Процес впровадження  може складатись з декількох етапів, таких як аудит та аналіз бізнес-процесів, вибір та налаштування відповідного програмного забезпечення, тестування та навчання персоналу. Зазвичай, це займає від 1 тижня на маленьких проектах до півроку і більше - на великих.`,
        expand: "b",
    },
    {
        title: "Які CRM-системи ви впроваджуєте?",
        desc: `Ми пропонуємо нашим клієнтам міжнародну українську платформу Creatio. Платформа має дуже потужний і  широкий функціонал, входить в топ-5-10 кращих платформ у світі за різними рейтингами. Наші фахівці добре знайомі з іншими продуктами, представленими на ринку, але, по-перше ми обираємо працювати з найкращими, а по-друге - глибока експертиза досягається тоді, коли працюєш з одним продуктом і не розпилюєш свої знання, увагу та зусилля.`,
        expand: "c",
    },
    {
        title: "Як нашій компанії навчити всіх співробітників працювати в новій CRM-системі?",
        desc: `Ми маємо комплексний навчальний план, що забезпечує ефективне навчання всіх співробітників у використанні нової CRM-системи, максимальний практичний ефект, та підтримку після запуску. Ми не можемо гарантувати відсутність саботажу співробітників (бо це залежить від багатьох факторів), але за 10 років роботи в цій сфері ми розробили такі методики, які знижують потенційний саботаж до мінімуму.`,
        expand: "d",
    },
    {
        title: "Чи допоможе аудит бізнес-процесів збільшити продажі?",
        desc: `Ні, аудит бізнес-процесів не має на меті збільшення продажів, але він допоможе виявити  слабкі місця у воронці продажів, ті ділянки, які можна автоматизувати, та розробити дієві кроки для збільшення конверсії продажів. І вже за результатами аудиту будуть запропоновані конкретні рекомендації по оптимізації процесів.`,
        expand: "f",
    },
    {
        title: "Які умови технічної підтримки після запуску?",
        desc: `Користуючись своїм досвідом ми розробили для наших клієнтів пакети технічної підтримки, які дозволять Вам бути спокійними та впевненими в роботі системи.
    Більш детальну інформацію Ви можете подивитись (посилання на сторінку)`,
        expand: "g",
    },
    {
        title: "Чи можна розібратися в інтерфейсі Creatio без вашої допомоги?",
        desc: `Система Creatio позиціонується як No-Code платформа, яка не потребує спеціалізованих знань та навичок для її використання. Але наші фахівці навчать Вас набагато швидше та ефективніше, і Ви не будете набивати шишки в процесі навчання. Наш комплексний підхід економить Ваш час та гроші.`,
        expand: "h",
    },
    {
        title: "Навіщо моїй компанії CRM-система?",
        desc: `CRM-система допоможе вам ефективніше управляти взаєминами з клієнтами, покращити продуктивність вашого відділу продажів та збільшити дохідність вашого бізнесу. Завдяки CRM-системі ви зможете централізовано зберігати інформацію про клієнтів, стежити за їхніми потребами та історією взаємодії з компанією. Ви зможете налагоджувати персоналізовані комунікації, маркетинг, прогнозувати та аналізувати продажі, ефективно розподіляти ресурси та багато іншого. CRM-система стане Вашим надійним помічником у веденні бізнесу та допоможе Вам забезпечити високу задоволеність клієнтів, розширити вашу клієнтську базу та збільшити прибуток компанії. Основна задача CRM-системи -  приймати рішення на основі даних, а не суджень та емоцій.`,
        expand: "i",
    },
    {
        title: "Cкільки коштує автоматизація бізнес-процесів?",
        desc: `Вартість впровадження CRM-системи оцінюється в залежності від обсягу трудовитрат наших фахівців. Після проведення передпроектного обстеження Вашого бізнесу (це безкоштовна послуга) ми зможемо порахувати вартість впровадження конкретно під Ваші вимоги та побажання, та зробити комерційну пропозицію. В середньому послуги з впровадження CRM-системи для невеликих компаній стартують від 1,5- 2 тис. доларів США. Бюджет для середніх та великих компаній зазвичай складає від 5-7 тис.доларів США і більше.`,
        expand: "t",
    },
];

const ServiceConsult = () => {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModalOne() {
        setIsOpen(!isOpen);
    }
    return (
        <div className="main-page-wrapper p0">
            <Helmet>
                <title>Консалтинг проектів автоматизаціїї | CRM Genesis</title>
                <meta
                    property="og:title"
                    content="Консалтинг проектів автоматизаціїї | CRM Genesis"
                />
                <meta
                    name="keywords"
                    content="консалтинг бізнес проектів, консалтинг з автоматизації"
                />
                <meta
                    name="description"
                    content="Аналіз, планування та контроль всіх етапів впровадження CRM-системи.
                Наші фахівці допоможуть реалізувати потужний потенціал автоматизації,
                підвищити ефективність, знизити витрати та оптимізувати бізнес-процеси"
                />
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderTwo/>
            {/* End Header */}

            {/* =============================================
            Fancy Hero One
        ==============================================  */}
            <div className="fancy-hero-five">
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="text-center row">
                            <div className="col-xl-9 m-auto">
                                <h1 className="heading">Консалтинг проектів автоматизації</h1>
                                <p className="sub-heading space-xs  mt-15">Забудьте про складність впровадження
                                    автоматизації в Вашому бізнесі! Наша команда фахівців допоможе Вам проаналізувати,
                                    спланувати, та проконтролювати всі етапи впровадження CRM-системи
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.bg-wrapper --> */}
            </div>
            {/* <!-- /.fancy-hero-five --> */}

            {/* =============================================
            Service Details One
        ==============================================  */}
            <div className="service-details-one pt-150 md-pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-8 order-lg-last">
                            <div className="details-wrapper pl-xl-5">
                                <h2 className="fs-4">
                                    Допоможемо автоматизувати бізнес-процеси та впровадити IT-рішення
                                </h2>
                                <p className="sub-heading  pt-30 pb-70 md-pb-40">Наші фахівці
                                    здійснять глибокий аналіз ваших бізнес-процесів, виявлять можливості для
                                    автоматизації і розроблять індивідуальні рішення, які насамперед відповідатимуть саме
                                    Вашим потребам.
                                </p>

                                <img
                                    src="/images/genesis/розділ консалтинг.jpg"
                                    alt="gallery"
                                    className="img-meta w-100"
                                />

                                <p className="pt-40 pb-40">Наші фахівці мають багаторічний досвід в галузі
                                    автоматизації, та готові працювати
                                    пліч-о-пліч з Вами, щоб забезпечити успішне впровадження нових технологій. Ми
                                    розуміємо, що кожен проект має унікальні вимоги і особливості, тому ми пропонуємо
                                    індивідуальний підхід, враховуючи Ваші потреби і цілі. Наша мета - допомогти Вам
                                    отримати найкращий результат з автоматизації процесів і підвищити ефективність
                                    вашого бізнесу. </p>

                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className=" pt-40">Наша мета:</h4>
                                        <ul className="list-item-one">
                                            <li>Ефективні рішення</li>
                                            <li>Збільшення продуктивності</li>
                                            <li>Надійні автоматизовані системи</li>
                                            <li>Довгострокові партнерські відносини</li>
                                            <li>Складне робимо простим</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className=" pt-40">Очікуваний результат:</h4>
                                        <p>
                                            Документи, в яких описується концепція, процеси, плани та рішення.
                                        </p>
                                    </div>
                                </div>
                                {/* End .row */}

                                <h3 className=" pt-90 pb-60 md-pt-50">
                                    Питання та відповіді
                                </h3>
                                <FaqClassicThree FaqContent={FaqContent}/>
                            </div>
                            {/* <!-- /.details-wrapper --> */}
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 order-lg-first">
                            <div className="sidebar md-mt-100">
                                <ServiceBlock/>
                                <ServiceRandomNote/>
                            </div>
                            {/* <!-- /.sidebar --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /.service-details-one --> */}

            {/*
      =============================================
            Footer
        ============================================== */}
            <div className="footer-bg-wrapper">
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="fancy-short-banner-two">
                    <div className="container">
                        <div className="content-wrapper">
                            <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                                <h2 className="font-gilroy-bold">
                                    Допоможемо автоматизувати бізнес-процеси
                                </h2>

                                <Link onClick={() => setIsOpen(true)}> Звʼязатись з нами </Link>
                                <MainModal title="Допоможемо автоматизувати бізнес-процеси" isOpen={isOpen} setIsOpen={setIsOpen}/>

                                <div className="bubble-one"></div>
                                <div className="bubble-two"></div>
                                <div className="bubble-three"></div>
                                <div className="bubble-four"></div>
                                <div className="bubble-five"></div>
                            </div>
                            {/* /.bg-wrapper */}
                        </div>
                        {/*  /.content-wrapper */}
                    </div>
                    {/* /.container */}
                </div>
                {/* /.fancy-short-banner-two */}

                <footer className="theme-footer-two pt-150 md-pt-80">
                    <div className="top-footer">
                        <div className="container">
                            <FooterTwo/>
                        </div>
                        {/* /.container */}
                    </div>
                    {/* /.top-footer */}

                    <div className="container">
                        <div className="bottom-footer-content">
                            <CopyRightTwo/>
                        </div>
                        {/*  /.bottom-footer */}
                    </div>
                </footer>
                {/* /.theme-footer-one */}
            </div>
        </div>
    );
};

export default ServiceConsult;
