import React from "react";
import {Link} from "react-router-dom";

const TeamContent = [
    {
        designation: "Директор, власник",
        name: "Олексій",
        img: "man_3",
        animationDelay: "200",
    },
    {
        designation: "Керівник відділу розробки",
        name: "Андрій",
        img: "man_2",
        animationDelay: "250",
    },
    {
        designation: "Розробник",
        name: "Антон",
        img: "man_1",
        animationDelay: "300",
    },
    {
        designation: "Маркетолог",
        name: "Інна",
        img: "girl_3",
        animationDelay: "100",
    },
    {
        designation: "Розробник",
        name: "Костянтин",
        img: "man_4202841",
        animationDelay: "150",
    },
    {
        designation: "Керівник відділу аналітиків",
        name: "Ілона",
        img: "girl_1",
        animationDelay: "250",
    },
    {
        designation: "Розробник",
        name: "Костянтин",
        img: "man_4202843",
        animationDelay: "50",
    },
    {
        designation: "Розробник",
        name: "Дмитро",
        img: "man_4202835",
        animationDelay: "150",
    },
    {
        designation: "Розробник",
        name: "Ілля",
        img: "boy_4202849",
        animationDelay: "250",
    },

    {
        designation: "Розробник",
        name: "Юрій",
        img: "boy_4202845",
        animationDelay: "50",
    },
    {
        designation: "Аналітик-інтегратор",
        name: "Павло",
        img: "man_4202841",
        animationDelay: "100",
    },
    {
        designation: "Аналітик-інтегратор",
        name: "Максим",
        img: "man_4202841",
        animationDelay: "150",
    },
    {
        designation: "Розробник",
        name: "Олексій",
        img: "man_4202841",
        animationDelay: "250",
    },
    {
        designation: "Системний адміністратор",
        name: "Олександр",
        img: "boy_4202831",
        animationDelay: "50",
    },
    {
        designation: "Бухгалтер",
        name: "Вікторія",
        img: "girl_2",
        animationDelay: "100",
    },
    {
        designation: "Аналітик-інтегратор",
        name: "Людмила",
        img: "girl_3",
        animationDelay: "150",
    },
];

const TeamFour = () => {
    return (
        <>
            {TeamContent.map((item, i) => (
                <div
                    className="col-lg-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay={item.animationDelay}
                    key={i}
                >
                    <div className="team-member">
                        <img src={`images/media/${item.img}.png`} alt="team"/>
                        <div className="name">{item.name}</div>
                        <div className="position">{item.designation}</div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default TeamFour;
