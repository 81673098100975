import React from "react";

const FeatureContent = [
  {
    icon: "163",
    meta: "Якість продуктів, що надаються клієнтам",
    subTitle: `Це базова цінність, довкола якої будується сама компанія`,
    dataDelay: "0",
  },
  {
    icon: "162",
    meta: "Співробітники та їхнє професійне зростання",
    subTitle: `Побудова сильної компанії через професійне та особистісне зростання`,
    dataDelay: "100",
  },
  {
    icon: "158",
    meta: "Постійні клієнти",
    subTitle: `Від першого знайомства до підписання договору, здачі проекту та подальшої підтримки`,
    dataDelay: "300",
  },
  {
    icon: "165",
    meta: "Чесність у роботі",
    subTitle: `Чесна оцінка проекту, чесна співпраця, чесна відмова, чесне визнання помилок`,
    dataDelay: "400",
  },
  {
    icon: "159",
    meta: "Відповідальність у всьому",
    subTitle: `Ми віримо в силу рукостискання. Форма домовленості (усна чи письмова) не має значення`,
    dataDelay: "500",
  },
  {
    icon: "157",
    meta: "Партнерські взаємини",
    subTitle: `Ми завжди виберемо співпрацю там, де це можливо, замість конкуренції`,
    dataDelay: "600",
  },
];

const FancyFeatureSeven = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6 mb-4"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-seven">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" width="50px"/>
            </div>
            <div className="feature-info">
              <span>{val.meta}</span>
            </div>
            <p className="font-rubik">{val.subTitle}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default FancyFeatureSeven;
