import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../../components/header/Header";
import PortfolioFour from "../../../components/portfolio/PortfolioFour";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import {Link} from "react-router-dom";
import MainModal from "../../../components/modals";

const PortfolioV3 = () => {
    const [isOpen, setIsOpen] = useState(false);
    function toggleModalOne() {
        setIsOpen(!isOpen);
    }
    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>Портфоліо CRM Genesis. 500+ успішних проєктів</title>
                <meta
                    property="og:title"
                    content="Портфоліо CRM Genesis. 500+ успішних проєктів"
                />
                <meta
                    name="description"
                    content="Інтеграція з CRM для різних галузей та розміру компанії. Знайдемо рішення саме для Вас. CRM Genesis: ✔10 років на ринку ✔500+ успішних проєктів ✔7 країн"
                />
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderTwo/>
            {/* End Header */}

            {/* =============================================
         Fancy Hero Six
        ==============================================  */}
            <div className="fancy-hero-six">
                <div className="container">
                    <h1 className="heading">Портфоліо</h1>
                    <p className="sub-heading">
                        Результати говорять самі за себе
                    </p>
                </div>
            </div>
            {/* /.fancy-hero-six */}

            {/*  =============================================
            Fancy Portfolio Two
        ==============================================  */}
            <div className="fancy-portfolio-four lg-container">
                <div className="container">
                    <div className="top-border bottom-border pb-130 md-pb-90">
                        <PortfolioFour/>

                    </div>
                </div>
            </div>
            {/* /.fancy-portfolio-two */}

            {/* =====================================================
            Footer Style Seven
        ===================================================== */}
            <div className="fancy-short-banner-two">
                <div className="container">
                    <div className="content-wrapper">
                        <div
                            className="
                  bg-wrapper
                  d-lg-flex
                  align-items-center
                  justify-content-between
                "
                        >
                            <h2 className="font-gilroy-bold">
                                Розрахувати проєкт?
                            </h2>
                            <MainModal title="Розрахувати проєкт?" isOpen={isOpen} setIsOpen={setIsOpen}/>

                            <Link onClick={() => setIsOpen(true)}> Звʼязатись з нами </Link>
                            <div className="bubble-one"></div>
                            <div className="bubble-two"></div>
                            <div className="bubble-three"></div>
                            <div className="bubble-four"></div>
                            <div className="bubble-five"></div>
                        </div>
                        {/* /.bg-wrapper */}
                    </div>
                    {/*  /.content-wrapper */}
                </div>
                {/* /.container */}
            </div>
            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo/>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-seven */}
        </div>
    );
};

export default PortfolioV3;
