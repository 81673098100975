import React from "react";
import { Link } from "react-router-dom";

const FeatureContent = [
  {
    icon: "20",
    meta: "Автоматизація процесів",
    subTitle: `Налагодження швидкої та комфортної роботи компанії`,
    dataDelay: "0",
    link: "/service-details",
  },
  {
    icon: "21",
    meta: "Аудит бізнес-процесів",
    subTitle: `Дослідження та об’єктивна оцінка Вашого бізнесу`,
    dataDelay: "100",
    link: "/service-audit",
  },
  {
    icon: "22",
    meta: "Навчання роботі в CRM",
    subTitle: `Практичне, зрозуміле навчання Ваших співробітників`,
    dataDelay: "0",
    link: "/service-learning",
  },
  {
    icon: "21",
    meta: "Впровадження CRM-систем",
    subTitle: `Налаштування CRM системи під потреби Вашого бізнесу`,
    dataDelay: "100",
    link: "/service-implement",
  },
  {
    icon: "22",
    meta: "Консалтинг з автоматизації",
    subTitle: `Планування та контроль всіх етапів впровадження CRM`,
    dataDelay: "0",
    link: "/service-consult",
  },
  {
    icon: "20",
    meta: "Технічна підтримка",
    subTitle: `Професійний підхід до супровіду Вашого проєкту`,
    dataDelay: "100",
    link: "/support",
  },
];

const FeatureSixV2 = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <Link to={val.link} className="block-style-five">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h6 className="title">
              <span>{val.meta}</span>
            </h6>
            <p>{val.subTitle}</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default FeatureSixV2;
