import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";

const HeaderPopupForm = () => {
    const [formSent, setFormSent] = useState(false);
    // for validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required(" Name is required"),
        email: Yup.string()
            .required("Email is required")
            .email("Entered value does not match email format"),
        sendMessage: Yup.string().required("Please,leave us a message."),
    });

    const formOptions = {resolver: yupResolver(validationSchema)};
    // get functions to build form with useForm() hook
    const {register, handleSubmit, formState} = useForm(formOptions);
    const {errors} = formState;

    function onSubmit(data, e) {
        // display form data on success
        e.preventDefault();

        const apiUrl = "https://crmgenesis.creatio.com/0/ServiceModel/GeneratedObjectWebFormService.svc/SaveWebFormObjectData";

        const requestBody = {
            formData: {
                formId: "52656db1-11a4-4a43-8b8a-d1c35b7908b3",
                formFieldsData: [
                    {name: "Name", value: data.name},
                    {name: "UseEmail", value: data.email},
                    {name: "MobilePhone", value: data.phone},
                    {name: "Commentary", value: data.sendMessage},
                ],
                contactFieldsData: [
                    {name: "FullName", value: data.name},
                    {name: "Phone", value: data.phone},
                    {name: "Email", value: data.email},
                ],
                options: {extendResponseWithExceptionType: true},
            },
        };

        fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                // "bpmTrackingId": bpmTrackingId,
            },
            body: JSON.stringify(requestBody),
        })
            .then((data) => {
                console.log("Response data:", data);

                window.gtag('event', 'contact_form');
                setFormSent(true);
            })
            .catch((error) => {
                console.error("Error:", error);
                // Обработка ошибки при выполнении запроса
            });
        e.target.reset();
    }

    return (
        <>
            {formSent ?
                <div className="d-flex flex-column justify-content-between align-items-center p-5 text-center">
                    <img src="images/icon/19.svg" alt="close" style={{height: '50px', marginBottom: 20}}/>
                    <p>Ваша заявка відправлена. Дякуємо за ваш запит! Наші фахівці зв'яжуться з вами найближчим часом,
                        щоб обговорити деталі і надати необхідну інформацію.</p>
                </div>
                :
                <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="messages"></div>
                    <div className="row controls">
                        <div className="col-12">
                            <div className="input-group-meta form-group mb-20">
                                <label>Прізвище та імʼя</label>
                                <input
                                    type="text"
                                    placeholder="Ваше імʼя"
                                    name="name"
                                    {...register("name")}
                                    className={`${errors.name ? "is-invalid" : ""}`}
                                />
                                {errors.name && (
                                    <div className="invalid-feedback">{errors.name?.message}</div>
                                )}
                            </div>
                        </div>
                        {/* End .col */}

                        <div className="col-6">
                            <div className="input-group-meta form-group mb-20">
                                <label>Телефон*</label>
                                <input
                                    placeholder="Телефон"
                                    name="phone"
                                    type="text"
                                    {...register("phone")}
                                    className={` ${errors.phone ? "is-invalid" : ""}`}
                                />
                                {errors.phone && (
                                    <div className="invalid-feedback">{errors.phone?.message}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="input-group-meta form-group mb-20">
                                <label>Email*</label>
                                <input
                                    placeholder="Email"
                                    name="email"
                                    type="text"
                                    {...register("email")}
                                    className={` ${errors.email ? "is-invalid" : ""}`}
                                />
                                {errors.email && (
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                )}
                            </div>
                        </div>
                        {/* End .col */}

                        <div className="col-12">
                            <div className="input-group-meta form-group mb-30">
                                <label>Запит*</label>
                                <textarea
                                    placeholder="Ваш запит"
                                    name="sendMessage"
                                    type="text"
                                    {...register("sendMessage")}
                                    className={`${errors.sendMessage ? "is-invalid" : ""}`}
                                ></textarea>
                                {errors.sendMessage && (
                                    <div className="invalid-feedback">
                                        {errors.sendMessage?.message}
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* End .col */}

                        <div className="col-12">
                            <button className="theme-btn-seven w-100">Надіслати</button>
                        </div>
                        {/* End .col */}
                    </div>
                </form>
            }
        </>
    );
};

export default HeaderPopupForm;
