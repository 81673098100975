import React from "react";
import {Link} from "react-router-dom";
import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import {SRLWrapper} from "simple-react-lightbox";

function filterPortfolioItems(portfolioItems, criterion) {
    return portfolioItems.filter(item => item.meta === criterion);
}

const TabListContent = ["Всі", "Будівництво", "Торгівля", "Послуги", "Фін.сектор", "Медицина", "Страхування", "Юридичні\u00A0послуги", "Логістика", "Рекрутинг", "IT", "Медіа", "Освіта", "Автовиробник", "Спорт"];

const AllPortfolioItem = [
    {
        img: "bd.png",
        title: "BD Holding",
        meta: "Будівництво",
        dalayAnimation: "0",
    },
    {
        img: "holz.png",
        title: "Holz",
        meta: "Торгівля",
        dalayAnimation: "100",
    },
    {
        img: "Рисунок5.png",
        title: "Твоє коло",
        meta: "Фін.сектор",
        dalayAnimation: "200",
    },
    {
        img: "invivo.png",
        title: "INVIVO",
        meta: "Медицина",
        dalayAnimation: "300",
    },
    {
        img: "scandia.png",
        title: "Scandia",
        meta: "Будівництво",
        dalayAnimation: "0",
    },
    {
        img: "ankar.jpg",
        title: "Ankar Україна",
        meta: "Торгівля",
        dalayAnimation: "100",
    },
    {
        img: "budova.jpg",
        title: "Будова",
        meta: "Будівництво",
        dalayAnimation: "200",
    },
    {
        img: "luxfm.png",
        title: "LuxFM",
        meta: "Медіа",
        dalayAnimation: "300",
    },
    {
        img: "epicentrk.png",
        title: "ЕпіцентрК",
        meta: "Торгівля",
        dalayAnimation: "0",
    },
    {
        img: "boyden.png",
        title: "Boyden",
        meta: "Рекрутинг",
        dalayAnimation: "100",
    },
    {
        img: "tucha.png",
        title: "Tucha",
        meta: "IT",
        dalayAnimation: "200",
    },
    {
        img: "comfort_life.png",
        title: "Comfort Life",
        meta: "Будівництво",
        dalayAnimation: "300",
    },
    {
        img: "stream.png",
        title: "Stream Telecom",
        meta: "IT",
        dalayAnimation: "0",
    },
    {
        img: "andre_tan.jpg",
        title: "Andre Tan Academy",
        meta: "Освіта",
        dalayAnimation: "100",
    },
    {
        img: "kmbs.png",
        title: "KMBS",
        meta: "Освіта",
        dalayAnimation: "100",
    },
    {
        img: "thisispivbar.jpg",
        title: "This is Пивбар",
        meta: "Послуги",
        dalayAnimation: "200",
    },
    {
        img: "sherp.jpg",
        title: "Sherp",
        meta: "Автовиробник",
        dalayAnimation: "300",
    },
    {
        img: "gigacloud.png",
        title: "Giga Cloud",
        meta: "IT",
        dalayAnimation: "0",
    },
    {
        img: "synergy.png",
        title: "Synergy",
        meta: "Юридичні послуги",
        dalayAnimation: "100",
    },
    {
        img: "bayadera.jpg",
        title: "Bayadera",
        meta: "Торгівля",
        dalayAnimation: "200",
    },
    {
        img: "bac.png",
        title: "BAC",
        meta: "Страхування",
        dalayAnimation: "300",
    },
    {
        img: "veneto.jpeg",
        title: "ВЕНЕТО",
        meta: "Торгівля",
        dalayAnimation: "0",
    },
    {
        img: "chasgazy.jpeg",
        title: "Час Газу",
        meta: "Послуги",
        dalayAnimation: "100",
    },
    {
        img: "sk.jpeg",
        title: "SAYENKO KHARENKO",
        meta: "Юридичні послуги",
        dalayAnimation: "200",
    },
    {
        img: "zhyk.jpeg",
        title: "ЖЖУК",
        meta: "Торгівля",
        dalayAnimation: "300",
    },
    {
        img: "devik.jpeg",
        title: "DEVIK",
        meta: "Логістика",
        dalayAnimation: "0",
    },
    {
        img: "soctrade.jpeg",
        title: "SocTrade",
        meta: "Торгівля",
        dalayAnimation: "100",
    },
    {
        img: "atan.jpeg",
        title: "a.TaN",
        meta: "Торгівля",
        dalayAnimation: "200",
    },
    {
        img: "ef.jpeg",
        title: "EveryDay Fitness",
        meta: "Спорт",
        dalayAnimation: "300",
    },
    {
        img: "ffu.jpeg",
        title: "Федерація Футболу України",
        meta: "Спорт",
        dalayAnimation: "0",
    },
];

const PortfolioFour = () => {
    return (
        <SimpleReactLightbox>
            <Tabs className="portfolio-container">
                <div className="controls po-control-one text-center mb-90 md-mb-50 mt-90 md-mt-60">
                    <TabList className="d-flex flex-wrap justify-content-center">
                        {TabListContent.map((tab, i) => (
                            <Tab key={i}>
                                <button type="button" className="control">
                                    {tab}
                                </button>
                            </Tab>
                        ))}
                    </TabList>
                </div>
                {/* End pc-control-one */}
                <SRLWrapper>
                    <TabPanel>
                        <div className="mixitUp-container  gutter-space-one d-flex flex-wrap">
                            {AllPortfolioItem.map((item, i) => (
                                <div
                                    className="mix"
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                        {/* single mixitUp-container */}
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Будівництво").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Торгівля").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Послуги").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Фін.сектор").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Медицина").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Страхування").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Юридичні послуги").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Логістика").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Рекрутинг").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "IT").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Медіа").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Освіта").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Автовиробник").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="mixitUp-container gutter-space-one d-flex flex-wrap">
                            {filterPortfolioItems(AllPortfolioItem, "Спорт").map((item, i) => (
                                <div
                                    className={`mix ${item.imgClass}`}
                                    key={i}
                                    data-aos="fade-right"
                                    data-aos-delay={item.dalayAnimation}
                                >
                                    <div className="portfolio-block-two position-relative">
                                        <div className="d-flex align-items-center justify-content-center p-2">
                                            <img
                                                src={`images/gallery/${item.img}`}
                                                alt={item.meta}
                                                className="tran4s img-meta"
                                            />
                                            <div className="fancybox">
                                                <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className="hover-content">
                                            <h3>
                                                {item.title}
                                            </h3>
                                            <div className="tag">{item.meta}</div>
                                        </div>
                                        {/* /.hover-content */}
                                    </div>
                                    {/* /.portfolio-block-two */}
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                </SRLWrapper>
            </Tabs>
        </SimpleReactLightbox>
    );
};

export default PortfolioFour;
