import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import HeaderThree from "../../../components/header/HeaderThree";
import FaqClassicThree from "../../../components/faq/FaqClassicThree";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import ServiceBlock from "../../../components/service/ServiceBlock";
import ServiceRandomNote from "../../../components/service/ServiceRandomNote";
import MainModal from "../../../components/modals";


const FaqContent = [
    {
        title: "Скільки часу займе автоматизація мого бізнесу?",
        desc: `Час, необхідний для автоматизації вашого бізнесу, залежить від різних факторів, таких як кількість співробітників, складність процесів, кількість систем і додатків, які потрібно інтегрувати, та часу, який ви готові виділяти на комунікацію з нашими фахівцями (бо це двосторонній процес, який потребує вашого залучення). Процес впровадження  може складатись з декількох етапів, таких як аудит та аналіз бізнес-процесів, вибір та налаштування відповідного програмного забезпечення, тестування та навчання персоналу. Зазвичай, це займає від 1 тижня на маленьких проектах до півроку і більше - на великих.`,
        expand: "a",
    },
    {
        title: "Які CRM-системи ви впроваджуєте?",
        desc: `Ми пропонуємо нашим клієнтам міжнародну українську платформу Creatio. Платформа має дуже потужний і  широкий функціонал, входить в топ-5-10 кращих платформ у світі за різними рейтингами. Наші фахівці добре знайомі з іншими продуктами, представленими на ринку, але, по-перше ми обираємо працювати з найкращими, а по-друге - глибока експертиза досягається тоді, коли працюєш з одним продуктом і не розпилюєш свої знання, увагу та зусилля.`,
        expand: "b",
    },
    {
        title: "Чи допоможе аудит бізнес-процесів збільшити продажі?",
        desc: `Ні, аудит бізнес-процесів не має на меті збільшення продажів, але він допоможе виявити  слабкі місця у воронці продажів, ті ділянки, які можна автоматизувати, та розробити дієві кроки для збільшення конверсії продажів. І вже за результатами аудиту будуть запропоновані конкретні рекомендації по оптимізації процесів.`,
        expand: "c",
    },
    {
        title: "Які умови технічної підтримки після запуску?",
        desc: `Користуючись своїм досвідом ми розробили для наших клієнтів пакети технічної підтримки, які дозволять Вам бути спокійними та впевненими в роботі системи.
    Більш детальну інформацію Ви можете подивитись (посилання на сторінку)`,
        expand: "d",
    },
    {
        title: "Чи можна розібратися в інтерфейсі Creatio без вашої допомоги?",
        desc: `Система Creatio позиціонується як No-Code платформа, яка не потребує спеціалізованих знань та навичок для її використання. Але наші фахівці навчать Вас набагато швидше та ефективніше, і Ви не будете набивати шишки в процесі навчання. Наш комплексний підхід економить Ваш час та гроші.`,
        expand: "f",
    },
    {
        title: "Навіщо моїй компанії CRM-система?",
        desc: `CRM-система допоможе Вам ефективніше управляти взаєминами з клієнтами, покращити продуктивність вашого відділу продажів та збільшити дохідність вашого бізнесу. Завдяки CRM-системі ви зможете централізовано зберігати інформацію про клієнтів, стежити за їхніми потребами та історією взаємодії з компанією. Ви зможете налагоджувати персоналізовані комунікації, маркетинг, прогнозувати та аналізувати продажі, ефективно розподіляти ресурси та багато іншого. CRM-система стане Вашим надійним помічником у веденні бізнесу та допоможе Вам забезпечити високу задоволеність клієнтів, розширити вашу клієнтську базу та збільшити прибуток компанії. Основна задача CRM-системи -  приймати рішення на основі даних, а не суджень та емоцій.`,
        expand: "g",
    },
];


const ServiceAudit = () => {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModalOne() {
        setIsOpen(!isOpen);
    }
    return (
        <div className="main-page-wrapper p0">
            <Helmet>
                <title>Комплексний аудит бізнес-процесів | CRM Genesis</title>
                <meta
                    property="og:title"
                    content="Комплексний аудит бізнес-процесів"
                />
                <meta
                    name="keywords"
                    content="аудит бізнес-процесів, оптимізація бізнесу"
                />
                <meta
                    name="description"
                    content="Підвищення ефективності компаніїї завдяки аудиту бізнес-процесів.
                    Знайдемо рішення, виявимо проблеми, розробимо стратегії"
                />
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderTwo/>
            {/* End Header */}

            {/* =============================================
            Fancy Hero One
        ==============================================  */}
            <div className="fancy-hero-five">
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="text-center row">
                            <div className="col-xl-9 m-auto">
                                <h1 className="heading">Аудит бізнес-процесів</h1>
                                <p className="sub-heading space-xs  mt-15">Перевірка та оцінка роботи процесів у
                                    компанії</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- /.bg-wrapper --> */}
            </div>
            {/* <!-- /.fancy-hero-five --> */}

            {/* =============================================
            Service Details One
        ==============================================  */}
            <div className="service-details-one pt-150 md-pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-8 order-lg-last">
                            <div className="details-wrapper pl-xl-5">
                                <h2 className="fs-4">
                                    Проведіть аудит бізнес-процесів, позбавтесь “баласту” проблем
                                </h2>
                                <p className="sub-heading  pt-30 pb-70 md-pb-40">
                                    Аудит дозволяє отримати об'єктивну оцінку та поглянути на Ваші процеси з нового
                                    ракурсу. Він допоможе виявити потенційні недоліки, проблеми та можливості для
                                    поліпшення.
                                </p>
                                <img
                                    src="/images/genesis/аудит%20бп.jpg"
                                    alt="gallery"
                                    className="img-meta w-100"
                                />
                                <h4 className="pt-30 pb-30">Коли потрібен аудит бізнес-процесів:</h4>

                                <ul className="list-item-one">
                                    <li>Перед впровадженням нової системи чи технології, наприклад CRM, дослідження
                                        бізнес-процесів допоможе виявити проблеми в поточних процесах, які можуть
                                        вплинути
                                        на успіх нововведення. Аудит надасть можливість підготуватися до впровадження,
                                        врахувавши поточні проблеми та забезпечить кращі шанси на успіх.
                                    </li>
                                    <li>При зміні бізнес-моделі або стратегії аудит процесів може допомогти зрозуміти,
                                        який
                                        результат ви можете отримати від кожного новововедення.
                                    </li>
                                    <li>Якщо компанія зіткнулася з проблемами, такими як падіння продуктивності, високі
                                        рівні відмов або незадовільні результати, аудит допоможе виявити кореневі
                                        причини
                                        цих проблем та запропонувати шляхи їх вирішення.
                                    </li>
                                    <li>Компанії, які віддають перевагу постійному поліпшенню та інноваціям, можуть
                                        проводити регулярні аудити бізнес-процесів. Це дозволяє виявляти слабкі місця,
                                        шукати можливості для ефективності та якості, і впроваджувати покращення на
                                        постійній основі.
                                    </li>
                                </ul>

                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className=" pt-40">Наша мета:</h4>
                                        <ul className="list-item-one">
                                            <li>Аудит без складностей</li>
                                            <li>Зміна стратегії та її впровадження</li>
                                            <li>Розв'язання проблем</li>
                                            <li>Поліпшення на постійній основі</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <h4 className=" pt-40">Очікуваний результат:</h4>
                                        <p>
                                            Звіт за результатом аудиту.
                                        </p>
                                    </div>
                                </div>
                                {/* End .row */}

                                <h3 className=" pt-90 pb-60 md-pt-50">
                                    Питання та відповіді
                                </h3>
                                <FaqClassicThree FaqContent={FaqContent}/>
                            </div>
                            {/* <!-- /.details-wrapper --> */}
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6 order-lg-first">
                            <div className="sidebar md-mt-100">
                                <ServiceBlock/>
                                <ServiceRandomNote/>
                            </div>
                            {/* <!-- /.sidebar --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- /.service-details-one --> */}

            {/*
      =============================================
            Footer
        ============================================== */}
            <div className="footer-bg-wrapper">
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="fancy-short-banner-two">
                    <div className="container">
                        <div className="content-wrapper">
                            <div className="bg-wrapper d-lg-flex align-items-center justify-content-between">
                                <h2 className="font-gilroy-bold">
                                    Проведемо оцінку бізнес-процесів вашої компанії
                                </h2>
                                <Link onClick={() => setIsOpen(true)}> Звʼязатись з нами </Link>
                                <MainModal title="Проведемо оцінку бізнес-процесів вашої компанії" isOpen={isOpen} setIsOpen={setIsOpen}/>
                                <div className="bubble-one"></div>
                                <div className="bubble-two"></div>
                                <div className="bubble-three"></div>
                                <div className="bubble-four"></div>
                                <div className="bubble-five"></div>
                            </div>
                            {/* /.bg-wrapper */}
                        </div>
                        {/*  /.content-wrapper */}
                    </div>
                    {/* /.container */}
                </div>
                {/* /.fancy-short-banner-two */}

                <footer className="theme-footer-two pt-150 md-pt-80">
                    <div className="top-footer">
                        <div className="container">
                            <FooterTwo/>
                        </div>
                        {/* /.container */}
                    </div>
                    {/* /.top-footer */}

                    <div className="container">
                        <div className="bottom-footer-content">
                            <CopyRightTwo/>
                        </div>
                        {/*  /.bottom-footer */}
                    </div>
                </footer>
                {/* /.theme-footer-one */}
            </div>
        </div>
    );
};

export default ServiceAudit;
