import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import HeaderTwo from "../../../../components/header/HeaderTwo";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import FooterTwo from "../../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../../components/footer/CopyRightTwo";
import CounterThreeColored from "../../../../components/counter/CounterThreeColored";
import FancyTextBlockTen from "../../../../components/fancy-text-block/FancyTextBlockTen";
import FancyFeatureSeven from "../../../../components/features/FancyFeatureSeven";
import TeamFour from "../../../../components/team/TeamFour";
import MainModal from "../../../../components/modals";

const CustomerSupport = () => {
    const [isOpen, setIsOpen] = useState(false);
    function toggleModalOne() {
        setIsOpen(!isOpen);
    }
    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>
                    Наша історія команди | CRM GENESIS
                </title>
                <meta
                    property="og:title"
                    content="Наша історія команди | CRM GENESIS"
                />
                <meta
                    name="description"
                    content="CRM Genesis - інтегратор Creatio CRM. Автоматизуємо бізнес-процеси, проведемо аудит бізнес-процесів, навчино користуватись CRM. 500+ проєктів.10 років на ринку."
                />
            </Helmet>
            {/* End Page SEO Content */}

            {/* =============================================
		   Start Header
		   ============================================== */}
            <HeaderTwo/>
            {/* End HeaderTwo Variation Three */}

            {/* 	=============================================
				Fancy Hero Two
			==============================================  */}
            <div className="fancy-hero-two">
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="page-title">Про нас</div>
                        <div className="row">
                            <div className="col-xl-8 col-lg-10 col-md-10 m-auto">
                                <h1 className="heading">CRM Genesis</h1>
                                <p className="sub-heading">
                                    Ваш партнер в автоматизації бізнесу
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* End .container */}
                </div>
                {/* /.bg-wrapper */}
            </div>
            {/* /.fancy-hero-two */}

            {/* =============================================
				Fancy Text block Nine
			==============================================  */}
            <div className="fancy-text-block-nine mt-130 md-mt-80">
                <div className="shapes shape-one"></div>
                <div className="shapes shape-two"></div>
                <div className="shapes shape-three"></div>
                <div className="shapes shape-four"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 m-auto">
                            <p
                                className="text-meta"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                            >
                                Ми допомагаємо бізнесу впровадити CRM, автоматизувати бізнес-процеси, збільшити продажі,
                                рівень конверсії, навести порядок, і зробити роботу ефективною і системною. Допомагаємо
                                сильним бути ще сильніше, робимо складні речі простими і зрозумілими, завжди доводимо
                                розпочате до результату. Кожен наш фахівець - це експерт у своїй сфері. Працюємо з усіма
                                сферами бізнесу, але найбільшу експертизу щодо впровадження CRM-систем маємо в таких
                                сферах, як будівництво, e-commerce, фінанси, юридична практика, медицина і стоматологія,
                                логістика, краса і здоров’я, професійні послуги. Реалізовано понад 500 проектів
                                автоматизації за більше ніж 10 років роботи на ринку.
                            </p>
                        </div>
                    </div>
                    {/*    /!* End .row *!/*/}
                </div>
            </div>
            {/* /.fancy-text-block-nine */}
            <div className="fancy-text-block-ten mt-120 md-mt-60">
                <div className="container">
                    <FancyTextBlockTen/>
                </div>
            </div>

            <div className="fancy-feature-five mt-200 md-mt-100">
                <div className="bg-wrapper">
                    <div className="shapes shape-one"></div>
                    <div className="shapes shape-two"></div>
                    <div className="shapes shape-three"></div>
                    <div className="shapes shape-four"></div>
                    <img src="images/shape/47.svg" alt="shape" className="shapes shape-five"/>
                    <div className="container">
                        <div className="title-style-two text-center mb-60 md-mb-40">
                            <div className="row">
                                <div className="col-xl-8 col-lg-9 m-auto">
                                    <p>CRM GENESIS</p>
                                    <h2>Наші цінності та принципи</h2>
                                    <div className="sub-text">За 10 років існування ми сформували свій певний характер
                                        та цінності, які допомагають нам пропонувати клієнтам якісні продукти.
                                        Ми пропонуємо бізнесу лише те, у що самі віримо та чого дотримуємось.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <FancyFeatureSeven/>
                    </div>
                </div>
            </div>
            <div className="team-section-two mt-200 md-mt-100">
                <div className="shapes shape-one"></div>
                <div className="shapes shape-two"></div>
                <div className="shapes shape-three"></div>
                <div className="shapes shape-four"></div>
                <div className="container">
                    <div className="row align-items-center justify-content-between mb-80 md-mb-10">
                        <div className="col-lg-8">
                            <div className="title-style-two">
                                <p>Наша команда</p>
                                <h2>Наша талановита команда</h2></div>
                        </div>
                    </div>
                    <div className="row">
                        <TeamFour/>
                    </div>
                </div>
                <div className="fancy-short-banner-two mt-5">
                    <div className="container">
                        <div className="content-wrapper">
                            <div
                                className="
                  bg-wrapper
                  d-lg-flex
                  align-items-center
                  justify-content-between
                "
                            >
                                <h2 className="font-gilroy-bold">
                                    Хочу в команду
                                </h2>
                                <MainModal title="Хочу в команду" isOpen={isOpen} setIsOpen={setIsOpen}/>

                                <Link onClick={() => setIsOpen(true)}> Звʼязатись з нами </Link>
                                <div className="bubble-one"></div>
                                <div className="bubble-two"></div>
                                <div className="bubble-three"></div>
                                <div className="bubble-four"></div>
                                <div className="bubble-five"></div>
                            </div>
                            {/* /.bg-wrapper */}
                        </div>
                        {/*  /.content-wrapper */}
                    </div>
                    {/* /.container */}
                </div>
            </div>

            {/* 	=====================================================
				Footer Style Two
			===================================================== */}
            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo/>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default CustomerSupport;
