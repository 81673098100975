import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../../components/header/Header";
import PortfolioFive from "../../../components/portfolio/PortfolioFive";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import HeaderTwo from "../../../components/header/HeaderTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import {Link} from "react-router-dom";
import MainModal from "../../../components/modals";

const PortfolioV4 = () => {
    const [isOpen, setIsOpen] = useState(false);
    function toggleModalOne() {
        setIsOpen(!isOpen);
    }
    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>Партнери || Розширюємо горизонти співпраці</title>
                <meta
                    property="og:title"
                    content="Партнери || Розширюємо горизонти співпраці"
                />
                <meta
                    name="description"
                    content="Ми завжди виберемо співпрацю там, де це можливо, замість конкуренції
                Співпраця, заснована на взаємній повазі до наших партнерів, є основою для тривалих партнерських відносин"
                />
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderTwo/>
            {/* End Header */}

            {/* =============================================
         Fancy Hero Six
        ==============================================  */}
            <div className="fancy-hero-six">
                <div className="container">
                    <h1 className="heading">Партнери</h1>
                    <p className="sub-heading">
                        Розширюємо горизонти співпраці
                    </p>
                    <p className="sub-heading mt-5">
                        Ми віримо, що співпраця, заснована на взаємній повазі до наших партнерів, є основою для тривалих
                        партнерських відносин
                    </p>
                </div>
            </div>
            {/* /.fancy-hero-six */}

            {/*  =============================================
            Fancy Portfolio Two
        ==============================================  */}
            <div className="fancy-portfolio-five lg-container top-border bottom-border pb-130 pt-110">
                <div className="slider-wrapper">
                    <div className="portfolio_slider_one">
                        <PortfolioFive/>
                    </div>
                </div>
                {/* End .slider-wrapper */}
            </div>
            <div className="text-center">
                <Link onClick={() => setIsOpen(true)}
                      className="theme-btn-two trial-button mt-50 md-mt-30">
                    {" "}
                    Стати партнером
                </Link>
            </div>
            <MainModal title="Стати партнером" isOpen={isOpen} setIsOpen={setIsOpen}/>
            {/* /.fancy-portfolio-two */}

            {/* =====================================================
            Footer Style Seven
        ===================================================== */}
            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo/>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-seven */}
        </div>
    );
};

export default PortfolioV4;
