import React from "react";
import {Link} from "react-router-dom";

const ServiceBlock = () => {
    return (
        <div className="category-list">
            <h4 className="">Послуги</h4>
            <ul>
                <li>
                    <Link to="/service-details"
                       // className="active"
                    >
                        Автоматизація
                    </Link>
                </li>
                <li>
                    <Link to="/service-audit">Аудит</Link>
                </li>
                <li>
                    <Link to="/service-learning">Навчання</Link>
                </li>
                <li>
                    <Link to="/service-implement">Впровадження</Link>
                </li>
                <li>
                    <Link to="/service-consult">Консалтинг</Link>
                </li>
                <li>
                    <Link to="/support">Технічна підтримка</Link>
                </li>
            </ul>
        </div>
    );
}
export default ServiceBlock;
