import React from "react";
import {Helmet} from "react-helmet";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import {Link} from "react-router-dom";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";

const FaqContent = [
    {
        title: "Що означає no-code розробка?",
        description: "No-code розробка – це метод створення застосунків без програмування, що дозволяє як програмістам, так і нетехнічним користувачам автоматизувати процеси через візуальні інструменти та конструктори. Це швидше впроваджує інновації в IT-процеси, забезпечуючи простоту використання та ефективність на всіх етапах розробки додатків",
    },
    {
        title: "Чи підійде Creatio CRM для логістичної компанії?",
        description: "Creatio CRM підходить для різних галузей, таких як продажі, маркетинг, сервіс, фінанси, логістика, медицина, виробництво, та багато інших. У Creatio є готові галузеві рішення та автоматизація бізнес-процесів будь-якого рівня складності та направлення."
    },
    {
        title: "Чи складно впроваджувати CRM Creatio у свій бізнес?",
        description: "Creatio - це No-Code платформа, тому користувач може і самостійно налаштувати систему під свої потреби, подивившись відеоуроки на сайті Академії Creatio. Але для економії Вашого часу, та маючи більш ніж 10-річний досвід впроваджень, ми пропонуємо повний цикл впровадження Creatio від аналізу ваших бізнес-процесів до впровадження CRM та технічної підтримки. Вам обирати — зробити самостійно та зекономити, чи довірити впровадження досвідченим фахівцям. \n"
    },
    {
        title: "Чи може CRM Creatio інтегруватися з іншими системами?",
        description: "Так, CRM Creatio має можливості інтеграції з іншими системами через відкритий API. Для інтеграцій по API треба залучення програміста, бо це виходить за рамки концепції No-Code.\n"
    },
    {
        title: "Які можливості аналізу даних надає CRM Creatio?",
        description: "CRM Creatio надає розширені інструменти аналізу даних для прийняття обґрунтованих управлінських та стратегічних рішень. Інструментарій включає побудову графіків, показників, діаграм різного виду, зведених таблиць тощо. Також на графіки можна накладати динамічні фільтри, що суттєво розширює можливості, та замінює використання зовнішніх систем аналітики, таких як Power BI, або Data Studio.\n"
    },
    {
        title: "Які функції має ліцензія Sales в CRM Creatio?",
        description: "Sales Creatio - комплексна платформа для управління продажами та прискорення процесів продажів будь-якої складності. Обираючи ліцензію Sales ви отримаєте: портрет клієнта  360°, управління лідами та продажами, управління замовленнями та договорами, планування продажів, продуктивність продажів. "
    }, {
        title: "Чи проводите Ви навчання для користувачів CRM Creatio?",
        description: "Так, після впровадження CRM, ми проводимо навчання для Ваших співробітників, і на практиці показуємо як працювати в новій системі. Навчання проводиться в максимально практичному режимі, на конкретних прикладах, надається відеозапис навчання, та підтримка користувачів після навчання.\n"
    }, {
        title: "Які види підтримки доступні після впровадження CRM Creatio?",
        description: "Ми надаємо технічну підтримку, ви обираєте пакет підтримки та пріоритетність Ваших задач. Більше про тарифи та пріоритетність задач можна дізнатись <a href='/support'>тут</a>"
    },{
        title: "Для якого бізнесу підходить Creatio?",
        description: "Система підходить для будь-якого бізнесу, незалежно від його розміру. Однак важливо, щоб кількість користувачів у системі становила не менше 5 осіб. Оптимальним вибором Creatio буде для середніх та великих компаній, які динамічно розвиваються та масштабуються.\n"
    },{
        title: "Чи є рішення для автоматизації маркетингу?",
        description: "Так, ліцензія Marketing Creatio надає можливість для управління маркетинговими кампаніями на всіх етапах взаємодії з потенційними клієнтами. Marketing Creatio - це портрет клієнта 360°, управління рекламними кампаніями та розсилками, email-маркетинг, управління заходами, управління лідами, планування маркетингу."
    },{
        title: "Чи існує можливість індивідуалізації ролей та доступу користувачів у CRM Creatio?",
        description: "Так, ви маєте можливість налаштувати рівні доступу та обмеження для різних користувачів залежно від їх ролей, підрозділів, або посад в компанії, або зробити для конкретного співробітника повністю унікальні права. Права в Creatio налаштовуються не тільки на розділи, та операції, але й може налаштовуватись на кожне конкретне поле.\n"
    },{
        title: "Чи можна інтегрувати CRM Creatio з соціальними мережами?",
        description: "Так, ми інтегруємо CRM з месенджерами. В базовому функціоналі є інтеграція з Facebook, Telegram, Whats App. За допомогою додаткових застосунків з Marketplace можна розширити  цей перелік на майже всі наявні на ринку системи та платформи - Instagram, Viber, Skype, Twitter, Microsoft Teams, WeChat, Google Meet тощо \n"
    },{
        title: "Чи допомагаєте Ви з переходом з інших систем на Creatio?",
        description: "Так, ми допомагаємо бізнесу перейти на нову CRM-систему, переносимо всі дані зі старої системи в рамках можливостей тих систем віддати необхідні дані.\n"
    },{
        title: "Чи є можливість інтегрувати CRM Creatio з телефонією?\n",
        description: "Так, інтеграція з телефонією дозволяє вам здійснювати виклики та вести журнал дзвінків та фіксації розмов безпосередньо в системі. На сьогодні майже всі системи та платформи IP-телефонії вміють інтегруватись з Creatio, але деталі кожної конкретної інтеграції уточнюйте у наших фахівців."
    },
];

const Faq = () => {
    const handleSubmit = (event) => {
        event.preventDefault();
    };

    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>Відповіді на Ваші питання | CRM Genesis</title>
                <meta property="og:site_name" content="CRM Genesis"/>
                <meta property="og:type" content="website"/>
                <meta
                    property="og:title"
                    content="Відповіді на Ваші питання | CRM Genesis"
                />
                <meta
                    name="description"
                    content="✅ Що таке no-code ✅ Про перехід на нову CRM-систему. ✅ Про Marketing Creatio. ✅ Технічна підтримка на базі Creatio. Відповіли на часті питання про CRM Creatio та впровадження "
                />
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderTwo/>
            {/* End Header */}

            {/* =============================================
            Fancy Hero One
        ==============================================  */}
            <div className="fancy-hero-one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 m-auto">
                            <h1 className="font-rubik">Питання - відповіді</h1>
                            <p className="sub-heading">
                                Нижче зібрані часті питання та відповіді
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
                <div className="bubble-six"></div>
            </div>
            {/* /.fancy-hero-one */}

            {/* =============================================
            FAQS
        ==============================================  */}
            <div className="faqs-inner-page">
                <img
                    src="images/shape/66.svg"
                    alt="shape"
                    className="shapes shape-one"
                />
                <div className="shapes shape-two"></div>
                <div className="shapes shape-three"></div>
                <div className="shapes shape-four"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-11 m-auto">

                            {/* /.faqs-header */}

                            <div className="all-faqs">
                                <div className="faqs-all-qus">
                                    {FaqContent.map((item, i) => (
                                        <div
                                            className="article-preview d-flex"
                                            data-aos="fade-up"
                                            data-aos-duration="1200"
                                        >
                                            <div>
                                                <h3 className="font-rubik">{item.title}</h3>
                                                <div className="avatar-info" dangerouslySetInnerHTML={{ __html: item.description }} />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* /.faqs-all-qus  */}
                            </div>
                            {/*  /.all-faqs */}

                            <div
                                className="text-center more-faq-ask"
                                data-aos="fade-up"
                                data-aos-duration="1200"
                                data-aos-delay="100"
                            >
                                <h3 className="mb-35 font-rubik">Не знайшли потрібної відповіді?<br/>Поставте запитання</h3>
                                <a href="contact" className="theme-btn-one">
                                    Поставити запитання
                                </a>
                            </div>
                            {/* End more-faq-ask */}
                        </div>
                    </div>
                </div>
            </div>
            {/* /.faqs-inner-page */}

            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo/>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default Faq;
