import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";


const BlogStyle1 = () => {
    const [blogContent, setBlogContent] = useState([]);

    useEffect(() => {
        // Ваш запит до API
        fetch('https://panel.crmgenesis.com/api/posts')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Помилка при отриманні даних');
                }
                return response.json();
            })
            .then(data => {
                setBlogContent(data.data);
            })
            .catch(error => {
                console.error('Помилка при отриманні даних з API:', error);
            });
    }, []);

    if (!blogContent) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {blogContent.map((item, i) => (
                <div
                    className="col-lg-4 col-md-6"
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    key={i}
                >
                    <div className="post-meta">
                        {item.image &&
                            <img
                                src={item.image}
                                alt="media"
                                className="image-meta"
                            />
                        }
                        <div className="tag">{item.category}</div>
                        <h3>
                            <Link to={`/blog/${item.slug}`} className="title">
                                {item.title}
                            </Link>
                        </h3>
                        <Link
                            to={`/blog/${item.slug}`}
                            className="read-more d-flex justify-content-between align-items-center"
                        >
                            <span>Детальніше</span>
                            <i className="flaticon-right-arrow"></i>
                        </Link>
                    </div>
                    {/* /.post-meta */}
                </div>
            ))}
        </>
    );
};

export default BlogStyle1;
