import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PricingMonthly from "./PricingMonthly";
import PricingYearly from "./PricingYearly";

const Pricing = () => {
  return (
    <div>
      <Tabs>

        <div className="tab-content-wrpper">
          <TabPanel>
            <PricingMonthly />
          </TabPanel>
        </div>
      </Tabs>
    </div>
  );
};

export default Pricing;
