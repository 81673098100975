import React, {useState} from "react";
import {Helmet} from "react-helmet";
import HeaderTwo from "../../components/header/HeaderTwo";
import HeroBannerTwo from "../../components/hero-banner/HeroBannerTwo";
import FeatureFour from "../../components/features/FeatureFour";
import Faq from "../../components/faq/Faq";
import CounterThree from "../../components/counter/CounterThree";
import AboutTwo from "../../components/about/AboutTwo";
import Social from "../../components/social/Social";
import Testimonial from "../../components/testimonial/Testimonial";
import CallToActionThree from "../../components/call-to-action/CallToActionThree";
import FooterTwo from "../../components/footer/FooterTwo";
import CopyRightTwo from "../../components/footer/CopyRightTwo";
import CounterTwo from "../../components/counter/CounterTwo";
import FooterFour from "../../components/footer/FooterFour";
import FooterThree from "../../components/footer/FooterThree";
import FooterFive from "../../components/footer/FooterFive";
import FooterSix from "../../components/footer/FooterSix";
import FooterSeven from "../../components/footer/FooterSeven";
import {Link} from "react-router-dom";
import MainModal from "../../components/modals";

const CustomerSupport = () => {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModalOne() {
        setIsOpen(!isOpen);
    }
    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>Впровадження CRM в Україні | CRM GENESIS	</title>
            </Helmet>
            {/* End Page SEO Content */}

            {/* =============================================
		   Start Header
		   ============================================== */}
            <HeaderTwo/>
            {/* End HeaderTwo Variation Three */}

            {/* =============================================
			Hero Banner Two
		  ============================================== */}
            <HeroBannerTwo/>
            {/* End HeroBannerTwo */}

            {/* =============================================
				Fancy Feature Four
		  ============================================== */}
            <div className="fancy-feature-four mt-20">
                <div className="bg-wrapper">
                    <img
                        src="images/shape/18.svg"
                        alt="shape"
                        className="shapes shape-right"
                    />
                    <img
                        src="images/shape/19.svg"
                        alt="shape"
                        className="shapes shape-left"
                    />
                    <div className="container">
                        <div className="title-style-two text-center mb-100 md-mb-50">
                            <div className="row">
                                <div className="col-xl-8 col-lg-9 col-md-10 m-auto">
                                    <p>ЩО МИ РОБИМО</p>
                                    <h2>
                                        Робимо <span> ваш бізнес{" "}
                                        <img src="images/shape/line-shape-2.svg" alt="shape"/>
                    </span> структурованим, ефективним та автоматизованим
                                    </h2>
                                </div>
                                {/* End .col */}
                            </div>
                        </div>
                        {/* End .title */}

                        <div className="inner-content">
                            <img
                                src="images/shape/20.svg"
                                alt="shape"
                                className="shapes shape-one"
                            />
                            <img
                                src="images/shape/21.svg"
                                alt="shape"
                                className="shapes shape-two"
                            />
                            <FeatureFour/>
                        </div>
                        {/* End .inner-content */}
                    </div>
                </div>
            </div>
            {/* /.fancy-feature-four */}

            {/*
     =============================================
				Fancy Text Block Six
		============================================== */}
            <div className="fancy-text-block-six mt-250 md-mt-130">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6"
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >
                            <div className="title-style-three mb-35">
                                <p>ЄДИНА платформа</p>
                                <h2>
                  <span>
                    Для автоматизації {" "}
                      <img src="images/shape/line-shape-3.svg" alt="shape"/>
                  </span>
                                    процесів та CRM
                                </h2>
                            </div>
                            {/* End title */}
                            <div className="pricing-table-area-four">
                                <div className="pr-table-wrapper mt-0">
                                    <ul className="pr-feature">
                                        <li>Висока гнучкість налаштувань під будь-які потреби</li>
                                        <li>Готові рішення для продажів, маркетингу, сервісу</li>
                                        <li>Вбудований BPMN 2.0 конструктор бізнес-процесів</li>
                                        <li>Потужна система аналітики та візуалізації</li>
                                        <li>Мобільний додаток кастомізується та працює offline</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* End .col */}
                    </div>
                    {/* End .row */}
                </div>
                {/* End .container */}

                <div
                    className="img-meta-container"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                    data-aos-delay="100"
                >
                    <img src="images/assets/feature-creatio-00.jpeg" alt="feature"/>
                    <img
                        src="images/shape/22.svg"
                        alt="shape"
                        className="shapes shape-one"
                    />
                    <img
                        src="images/shape/23.svg"
                        alt="shape"
                        className="shapes shape-two"
                    />
                    <img
                        src="images/shape/24.svg"
                        alt="shape"
                        className="shapes shape-three"
                    />
                    <img
                        src="images/shape/25.svg"
                        alt="shape"
                        className="shapes shape-four"
                    />
                    <img
                        src="images/shape/26.svg"
                        alt="shape"
                        className="shapes shape-five"
                    />
                    <img
                        src="images/shape/27.svg"
                        alt="shape"
                        className="shapes shape-six"
                    />
                    <img
                        src="images/shape/28.svg"
                        alt="shape"
                        className="shapes shape-seven"
                    />
                </div>
            </div>
            {/* /.fancy-text-block-six  */}

            <div className="counter-with-icon-one pt-200 md-pt-80">
                <div className="container">
                    <CounterTwo/>
                </div>
            </div>
            {/* /.counter-with-icon-one */}

            {/*
     =============================================
				Fancy Text block Eight
		============================================== */}
            <div className="fancy-text-block-eight pt-150 pb-200 md-pt-100 md-pb-150">
                <div className="container">
                    <div className="title-style-two text-center mb-150 md-mb-70">
                        <div className="row">
                            <div className="col-xl-8 col-lg-9 col-md-10 m-auto">
                                <p>Можливості</p>
                                <h2>
                  <span>
                    Прискорення&nbsp;
                      <img src="images/shape/line-shape-2.svg" alt="shape"/>
                  </span> цифрової трансформації
                                </h2>
                            </div>
                            {/* End .col */}
                        </div>
                    </div>
                    {/* End .title */}

                    <div className="block-style-six pb-150 md-pb-70">
                        <div className="row">
                            <div
                                className="col-lg-5"
                                data-aos="fade-right"
                                data-aos-duration="1200"
                            >
                                <div className="text-details pt-35 md-pb-50">
                                    <img src="/images/icon/27.svg" alt="icon" className="icon"/>
                                    <h3 className="title font-gilroy-bold">
                                        Найкращі практики продажів
                                    </h3>
                                    <p className="text-meta pb-4">
                                        Прискорюйте повний цикл продажів: від ліда до повторних замовлень
                                    </p>
                                    <div className="pricing-table-area-four">
                                        <div className="pr-table-wrapper mt-0">
                                            <ul className="pr-feature">
                                                <li>Готовий процес управління довгими продажами</li>
                                                <li>Замовлення та рахунки</li>
                                                <li>Партнерські продажі</li>
                                                <li>Графіки поставок та оплат</li>
                                                <li>Продуктовий каталог та прайс-листи</li>
                                                <li>Планування продажів</li>
                                                <li>Аналітика з продажів</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <Link to="/creatio"
                                          className="theme-btn-two trial-button mt-50 md-mt-30">
                                        {" "}
                                        Докладніше
                                    </Link>
                                    {/* /.quote-wrapper */}
                                </div>
                                {/* /.text-details */}
                            </div>
                            {/* End .col */}

                            <div
                                className="col-lg-7 col-md-9 m-auto"
                                data-aos="fade-left"
                                data-aos-duration="1200"
                            >
                                <div className="illustration-holder illustration-one">
                                    <img
                                        src="images/assets/feature-creatio-01.jpeg"
                                        alt=""
                                        className="ml-auto"
                                    />
                                    <div className="shapes shape-one"></div>
                                    <div className="shapes shape-two"></div>
                                    <div className="shapes shape-three"></div>
                                    <div className="shapes shape-four"></div>
                                    <div className="shapes shape-five"></div>
                                    <img
                                        src="images/shape/35.svg"
                                        alt="shape"
                                        className="shapes shape-six"
                                    />
                                    <img
                                        src="images/shape/36.svg"
                                        alt="shape"
                                        className="shapes shape-seven"
                                    />
                                </div>
                            </div>
                            {/* End .col */}
                        </div>
                        {/* End .title */}
                    </div>
                    {/* /.block-style-six */}

                    <div className="block-style-six pt-150 md-pt-40">
                        <div className="row">
                            <div
                                className="col-lg-5 order-lg-last"
                                data-aos="fade-left"
                                data-aos-duration="1200"
                            >
                                <div className="text-details pt-35 md-pb-50">
                                    <img src="images/icon/29.svg" alt="" className="icon"/>
                                    <h3 className="title font-gilroy-bold">
                                        Омніканальність
                                    </h3>
                                    <p className="text-meta pb-4">
                                        Будьте на звʼязку і вибудовуйте індивідуальні коммунікації з кожним клієнтом
                                    </p>
                                    <div className="pricing-table-area-four">
                                        <div className="pr-table-wrapper mt-0">
                                            <ul className="pr-feature">
                                                <li>Інтеграція з корпоративною поштою та телефонією</li>
                                                <li>Вбудована чат-платформа</li>
                                                <li>Користувацький конструктор чат-ботів</li>
                                                <li>Велика кількість готових конекторів для об’єднання в єдиному
                                                    середовищі різних каналів зв’язку
                                                </li>
                                                <li>Історія комунікацій з прив’язкою до клієнта та продажу</li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* /.quote-wrapper */}
                                    <Link to="/contact"
                                          className="theme-btn-two trial-button mt-50 md-mt-30">
                                        {" "}
                                        Замовити презентацію
                                    </Link>
                                </div>
                                {/* /.text-details */}
                            </div>
                            {/* End .col */}

                            <div
                                className="col-lg-7 col-md-9 m-auto order-lg-first"
                                data-aos="fade-right"
                                data-aos-duration="1200"
                            >
                                <div className="illustration-holder illustration-two">
                                    <img
                                        src="images/assets/feature-creatio-02.jpeg"
                                        alt=""
                                        className="mr-auto"
                                    />
                                    <div className="shapes shape-one"></div>
                                    <div className="shapes shape-two"></div>
                                    <div className="shapes shape-three"></div>
                                    <div className="shapes shape-four"></div>
                                    <img
                                        src="images/shape/40.svg"
                                        alt="shape"
                                        className="shapes shape-five"
                                    />
                                    <img
                                        src="images/shape/41.svg"
                                        alt="shape"
                                        className="shapes shape-six"
                                    />
                                </div>
                            </div>
                            {/* End .col */}
                        </div>
                    </div>
                    {/* /.block-style-six  */}
                </div>
            </div>
            {/* /.fancy-text-block-eight */}

            {/*=====================================================
				Useable Tools
			===================================================== */}
            <div className="useable-tools-section-two bg-shape mb-200 md-mb-90">
                <div className="bg-wrapper">
                    <div className="shapes shape-one"></div>
                    <div className="shapes shape-two"></div>
                    <div className="shapes shape-three"></div>
                    <div className="shapes shape-four"></div>
                    <div className="container">
                        <div className="title-style-two text-center mb-70 md-mb-10">
                            <div className="row">
                                <div className="col-lg-10 col-md-11 m-auto">
                                    <p>Marketplace</p>
                                    <h2>
                                        Готові застосунки та шаблони для&nbsp;
                                        <span>
                      розширення можливостей
                      <img src="images/shape/line-shape-2.svg" alt=""/>
                    </span>
                                        платформи
                                    </h2>
                                </div>
                            </div>
                        </div>
                        {/*  /.title-style-two */}

                        <div className="icon-wrapper">
                            <Social/>
                        </div>
                        {/* /.icon-wrapper */}
                    </div>
                    {/* /.container */}
                </div>
                {/* /.bg-wrapper */}
            </div>
            {/* /.useable-tools-section-two */}

            <div className="fancy-short-banner-two">
                <div className="container">
                    <div className="content-wrapper">
                        <div
                            className="
                  bg-wrapper
                  d-lg-flex
                  align-items-center
                  justify-content-between
                "
                        >
                            <h2 className="font-gilroy-bold">
                                Залишились питання?
                            </h2>
                            <Link onClick={() => setIsOpen(true)}> Звʼязатись з нами </Link>
                            <MainModal title=" Залишились питання?" isOpen={isOpen} setIsOpen={setIsOpen}/>
                            <div className="bubble-one"></div>
                            <div className="bubble-two"></div>
                            <div className="bubble-three"></div>
                            <div className="bubble-four"></div>
                            <div className="bubble-five"></div>
                        </div>
                        {/* /.bg-wrapper */}
                    </div>
                    {/*  /.content-wrapper */}
                </div>
                {/* /.container */}
            </div>
            {/* 	=====================================================
				Footer Style Two
			===================================================== */}
            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo/>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default CustomerSupport;
