import React from "react";

const FeatureContent = [
    {
        icon: "20",
        meta: "Єдина система",
        subTitle: `Забезпечить роботу всіх працівників вашої компанії в єдиному середовищі для досягнення єдиних цілей`,
        dataDelay: "0",
    },
    {
        icon: "22",
        meta: "Єдині стандарти",
        subTitle: `Покращать конверсію продажів, рівень сервісу, ефективність маркетингу, та лояльності клієнтів`,
        dataDelay: "100",
    },
    {
        icon: "21",
        meta: "Повна автоматизація",
        subTitle: `Звільнить вас від монотонної роботи і дозволить зосередитись на більш важливих завданнях`,
        dataDelay: "300",
    },
];

const FeatureFour = () => {
    return (
        <div className="row justify-content-center">
            {FeatureContent.map((val, i) => (
                <div
                    className="col-lg-4 col-md-6"
                    key={i}
                    data-aos="fade-up"
                    data-aos-duration="1200"
                    data-aos-delay={val.dataDelay}
                >
                    <div className="block-style-five">
                        <div className="icon">
                            <img src={`images/icon/${val.icon}.svg`} alt="icon"/>
                        </div>
                        <h6 className="title">
                            <span>{val.meta}</span>
                        </h6>
                        <p>{val.subTitle}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FeatureFour;
