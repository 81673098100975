import HeaderPopupForm from "../form/HeaderPopupForm";
import Modal from "react-modal";
import React, {useEffect, useState} from "react";

const quotes = [
    {
        text: "Кожна успішна компанія заснована на відмінному відношенні до клієнтів.",
        author: "Варрен Баффет"
    },
    {
        text: "CRM - це не просто програмне забезпечення, це філософія ведення бізнесу, орієнтована на клієнта.",
        author: "Келвін Тобін"
    },
    {
        text: "Якщо ви не впроваджуєте CRM-систему в своєму бізнесі, ви губите гроші.",
        author: "Пітер Друкер"
    },
    {
        text: "Успіх у бізнесі полягає у здатності розуміти і задовольняти потреби клієнтів краще, ніж будь-яка інша компанія.",
        author: "Стів Джобс"
    },
    {
        text: "CRM дозволяє вам створити особистий зв'язок з кожним клієнтом, що робить вас більш конкурентоздатними.",
        author: "Джейф Гітмер"
    },
    {
        text: "Не існує ніякого більшого активу для компанії, ніж задоволені клієнти.",
        author: "Майк Мацуока"
    },
    {
        text: "Ключ до успіху - це надавати клієнтам те, чого вони хочуть, перш ніж вони це розуміють.",
        author: "Стів Джобс"
    },
    {
        text: "Велика компанія - це не лише технологія, але й вміння працювати з клієнтами та розвивати відносини з ними.",
        author: "Джек Ма"
    },
    {
        text: "Успішна компанія завжди ставить клієнта на перше місце.",
        author: "Річард Бренсон"
    },
];
const MainModal = ({title, isOpen, setIsOpen}) => {
    const [isOpenThanks, setIsOpenThanks] = useState(false);
    const [randomIndex, setRandomIndex] = useState(0);
    function toggleModalOne() {
        setIsOpen(!isOpen);
    }
    const min = 10;
    const max = 18;
    const randomValue = Math.floor(Math.random() * (max - min)) + min;
    const imagePath = `images/assets/ils_${randomValue}.svg`;

    useEffect(() => {
        // Генеруємо випадковий індекс при завантаженні компонента
        const newIndex = Math.floor(Math.random() * quotes.length);
        setRandomIndex(newIndex);
    }, []);


    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={toggleModalOne}
                contentLabel="My dialog"
                className="custom-modal  modal-contact-popup-one dark-style"
                overlayClassName="custom-overlay"
                closeTimeoutMS={500}
            >
                <div className="box_inner ">
                    <main className="main-body box_inner modal-content clearfix">
                        <button className="close" onClick={toggleModalOne}>
                            <img src="images/icon/close.svg" alt="close"/>
                        </button>
                        {/* End close icon */}

                        <div className="left-side">
                            <div className="d-flex flex-column justify-content-between h-100">
                                <div className="row">
                                    <div className="col-xl-10 col-lg-8 m-auto">
                                        <blockquote>{quotes[randomIndex].text}</blockquote>
                                        <span className="bio">—{quotes[randomIndex].author}</span>
                                    </div>
                                </div>
                                <img
                                    src={imagePath}
                                    alt=""
                                    className="illustration mt-auto"
                                />
                            </div>
                        </div>
                        {/* /.left-side */}

                        <div className="right-side">
                            <h2 className="form-title">{title}</h2>
                            <HeaderPopupForm/>
                        </div>
                        {/*  /.right-side */}
                    </main>
                    {/* /.main-body */}
                </div>
            </Modal>
        </>
    );
}

export default MainModal;