import React from "react";

const featuresContent = [

    {
        icon: "171",
        title: "Готові рішення",
        desc: `CRM для ефективних бізнес-процесів`,
        delayAnimation: "",
    },
    {
        icon: "173",
        title: "Швидка підтримка",
        desc: `Повний цикл підтримки`,
        delayAnimation: "200",
    },
    {
        icon: "172",
        title: "Автоматизований бізнес",
        desc: `Це збільшення конкуретної переваги`,
        delayAnimation: "100",
    },
];

const FancyFeatureThirtyThree = () => {
    return (
        <div className="row">
            {featuresContent.map((val, i) => (
                <div
                    className="col-lg-4"
                    data-aos="fade-up"
                    data-aos-delay={val.delayAnimation}
                    key={i}
                >
                    <div className="block-style-thirtyThree pr-lg-5 mt-40">
                        <div className="icon">
                            <img src={`images/icon/${val.icon}.svg`} alt="icon"/>
                        </div>
                        <h3 className="title">
                            <span>{val.title}</span>
                        </h3>
                        <p>{val.desc}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FancyFeatureThirtyThree;
