import React from "react";

const Social = () => {
  return (
    <ul className="clearfix">
      <li data-aos="fade-up" data-aos-duration="1200">
        <div className="icon-box d-flex align-items-center justify-content-center p-4">
          <img src="images/logo/teems.jpeg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/logo/10.png" alt="logo" />
        </div>
      </li>
      {/* End li */}

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
        <div className="icon-box d-flex align-items-center justify-content-center p-3">
          <img src="images/logo/jira.jpeg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/logo/powerbi.jpeg" alt="logo" />
        </div>
      </li>
      {/* End li */}

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/logo/13.png" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center p-3">
          <img src="images/logo/asterisk.png" alt="logo" />
        </div>
      </li>
      {/* End li */}

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
        <div className="icon-box d-flex align-items-center justify-content-center p-4">
          <img src="images/logo/wa.jpeg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/logo/18.png" alt="logo" />
        </div>
      </li>
      {/* End li */}

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
        <div className="icon-box d-flex align-items-center justify-content-center p-3">
          <img src="images/logo/zoom.jpeg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center p-4">
          <img src="images/logo/tg.jpeg" alt="logo" />
        </div>
      </li>
      {/* End li */}

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600">
        <div className="icon-box d-flex align-items-center justify-content-center p-3">
          <img src="images/logo/np.jpeg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center p-3">
          <img src="images/logo/shopy.jpeg" alt="logo" />
        </div>
      </li>
      {/* End li */}

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="700">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/logo/ChatGPT.png" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center p-3">
          <img src="images/logo/kyivstar.jpeg" alt="logo" />
        </div>
      </li>
      {/* End li */}

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="800">
        <div className="icon-box d-flex align-items-center justify-content-center p-4">
          <img src="images/logo/es.jpeg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/logo/24.png" alt="logo" />
        </div>
      </li>
      {/* End li */}
    </ul>
  );
};

export default Social;
