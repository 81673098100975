import React from "react";
import {Helmet} from "react-helmet";
import Header from "../../../components/header/Header";
import BlogStyle1 from "../../../components/blog/BlogStyle1";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";

const BlogV1 = () => {
    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>Блог компанії CRM Genesis. Статті</title>
                <meta
                    property="og:title"
                    content="Блог компанії CRM Genesis. Статті"
                />
                <meta
                    name="description"
                    content="Статті в блозі CRM Genesis: консалтинг проєктів автоматизації,аудит та автоматизація бізнес-процесів, впровадження CRM Creatio."
                />
            </Helmet>
            {/* End Page SEO Content */}
            <HeaderTwo/>
            {/* End Header */}

            {/* 	=============================================
            Fancy Hero One
        ==============================================  */}
            <div className="fancy-hero-one">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 col-lg-10 m-auto">
                            <h1 className="font-gilroy-bold">
                                Блог
                            </h1>
                        </div>
                        <div className="col-lg-9 m-auto">
                            <p className="font-rubik">
                                Тут ви знайдете новини нашої компанії та останні оновлення
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
                <div className="bubble-six"></div>
            </div>
            {/* /.fancy-hero-one */}

            {/* =====================================================
            Feature Blog One
        ===================================================== */}
            <div className="feature-blog-one blog-page-bg">
                <div className="shapes shape-one"></div>
                <div className="shapes shape-two"></div>
                <div className="shapes shape-three"></div>
                <div className="container">
                    <div className="row">
                        <BlogStyle1/>
                    </div>
                    {/* End .row */}
                    <div
                        className="text-center mt-30 md-mt-10"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        data-aos-delay="50"
                    >
                    </div>
                </div>
            </div>
            {/* /.feature-blog-one */}

            <footer className="theme-footer-two pt-130 md-pt-70">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo/>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default BlogV1;
