import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import NotFound from "../../NotFound";

const BlogDetails = ({match, history}) => {
    const {slug} = useParams();
    const [article, setArticle] = useState(null);
    const [status404, setStatus404] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        // Зробити запит до API з використанням fetch
        fetch(`https://panel.crmgenesis.com/api/posts/${slug}`)
            .then(response => {
                if (!response.ok) {
                    if (response.status === 404) {
                        setStatus404(true)
                    } else {
                        throw new Error('Помилка при виконанні запиту');
                    }
                }
                return response.json();
            })
            .then(data => {
                console.log(data);
                setArticle(data.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Помилка при отриманні статті:', error);
                setLoading(false);
            });
    }, [slug]);

    if (loading) {
        return <div>Loading...</div>;
    }
    if(status404){
        return <NotFound />
    }

    return (
        <div className="main-page-wrapper p0">
            <Helmet>
                <title>{article.title}</title>
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderTwo/>
            {/* End Header */}

            {/* =============================================
            Fancy Hero Two
        ==============================================  */}
            <div className="fancy-hero-five bg-white">
                <img
                    src="/images/shape/95.svg"
                    alt="shape"
                    className="shapes shape-one"
                />
                <img
                    src="/images/shape/96.svg"
                    alt="shape"
                    className="shapes shape-two"
                />
                <div className="bg-wrapper ">
                    <div className="container">
                        <div className="col-lg-10 m-auto text-center">
                            <h6 className="page-title">Блог</h6>
                            <h1 className="heading">
                                {article.title}
                                <img src="images/shape/line-shape-11.svg" alt=""/>
                            </h1>
                        </div>
                    </div>
                </div>
                {/* /.bg-wrapper */}
            </div>
            {/* /.fancy-hero-two */}

            {/* =====================================================
            Feature Blog One
        ===================================================== */}
            <div className="blog-page-bg">
                <div className="shapes shape-one"></div>
                <div className="shapes shape-two"></div>
                <div className="shapes shape-three"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
                            <div className="post-meta">
                                {article.image &&
                                    <img
                                        src={article.image}
                                        alt="media post"
                                        className="image-meta"
                                    />
                                }

                                {article.date &&
                                    <div className="tag">23 Apr. 2020</div>
                                }

                                <div dangerouslySetInnerHTML={{ __html: article.content }} />

                                <p></p>
                            </div>
                            {/* /End post content  */}


                        </div>
                        {/* End .col */}
                    </div>
                    {/* End .row */}
                </div>
            </div>
            {/* /.feature-blog-one */}

            <footer className="theme-footer-two pt-130 md-pt-70">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo/>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default BlogDetails;
