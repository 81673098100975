import React, {useState} from "react";
import {Helmet} from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import FancyVideoFive from "../../../components/video/FancyVideoFive";
import CounterThree from "../../../components/counter/CounterThree";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import FeatureSixV2 from "../../../components/features/FeatureSixV2";
import CallToActionThree from "../../../components/call-to-action/CallToActionThree";
import FancyFeatureThirtyThree from "../../../components/features/FancyFeatureThirtyThree";
import CallToActionTwo from "../../../components/call-to-action/CallToActionTwo";
import CallToActionFour from "../../../components/call-to-action/CallToActionFour";
import {Link} from "react-router-dom";
import MainModal from "../../../components/modals";

const ServiceV1 = () => {
    const [isOpen, setIsOpen] = useState(false);

    function toggleModalOne() {
        setIsOpen(!isOpen);
    }

    return (
        <div className="main-page-wrapper p0">
            <Helmet>
                <title>Наші послуги для Вашого бізнесу | CRM Genesis</title>
                <meta
                    property="og:title"
                    content="Професійні рішення для Вашого бізнесу | CRM Genesis"
                />
                <meta
                    name="description"
                    content="CRM Genesis пропонує професійні рішення для вашого бізнесу. Швидка підтримка. Автоматизація бізнес-процесів. Інтеграція та впровадження"
                />
            </Helmet>
            {/* End Page SEO Content */}

            <HeaderTwo/>
            {/* End Header */}

            {/* 	=============================================
            Fancy Hero Three
        ============================================== */}
            <div className="fancy-hero-three">
                <div className="shapes shape-one"></div>
                <div className="shapes shape-two"></div>
                <div className="shapes shape-three"></div>
                <div className="shapes shape-four"></div>
                <div className="shapes shape-five"></div>
                <div className="shapes shape-six"></div>
                <div className="bg-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 col-lg-10 m-auto">
                                <h1 className="heading">Наші послуги</h1>
                                <p className="sub-heading">
                                    CRM Genesis надає професійні рішення для автоматизації Вашого бізнесу
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.bg-wrapper  */}
            </div>
            {/* /.fancy-hero-three */}

            {/*   =============================================
        Fancy Text block Eleven
    ==============================================  */}
            <div className="fancy-text-block-eleven mt-200 md-mt-100">
                <div className="container">
                    <FancyVideoFive/>

                    <div className="mt-70">
                        <div className="container">
                            <FancyFeatureThirtyThree/>
                        </div>
                    </div>
                </div>
            </div>
            {/* /.fancy-text-block-eleven */}

            {/* =============================================
            Fancy Feature Six
        ============================================== */}
            <div className="fancy-feature-six mt-140 md-mt-70">
                <div className="bg-wrapper">
                    <div className="shapes shape-one"></div>
                    <div className="shapes shape-two"></div>
                    <div className="shapes shape-three"></div>
                    <div className="shapes shape-four"></div>
                    <div className="shapes shape-five"></div>
                    <img
                        src="images/shape/51.svg"
                        alt="shape"
                        className="shapes shape-six"
                    />
                    <div className="container">
                        <div className="title-style-two text-center mb-85 md-mb-40">
                            <h2>
                <span>
                  CRM Genesis пропонує Вашому бізнесу:
                  <img src="images/shape/line-shape-2.svg" alt="shape"/>
                </span>
                            </h2>
                        </div>
                        {/* End .title */}
                        <FeatureSixV2/>
                    </div>
                </div>
            </div>
            {/* /.fancy-feature-six */}

            {/* =====================================================
            Fancy Text block Twelve
        ===================================================== */}
            <div className="fancy-text-block-twelve mt-170 md-mt-100 mb-5">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-6"
                            data-aos="fade-right"
                            data-aos-duration="1200"
                        >
                            <div className="text-wrapper">
                                <div className="title-style-three">
                                    <h6>Чому ми?</h6>
                                    <h2>
                    <span>
                      Чому варто обирати
                      <img src="images/shape/line-shape-6.svg" alt=""/>
                    </span>
                                        CRM Genesis?
                                    </h2>
                                </div>
                                <p className="sub-text pb-35">
                                    Ми маємо багаторічний досвід та знаємо потреби різних сфер бізнесу. Ми пропонуємо:
                                </p>
                                <div className="service-details-one">
                                    <div className="details-wrapper">
                                        <ul className="list-item-one">
                                            <li>Надійну підтримку протягом процесу</li>
                                            <li>Підвищення конкурентоспроможності</li>
                                            <li>Автоматизація та контроль процесів</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /.text-wrapper --> */}
                        </div>
                        {/* End .col-6 */}

                        <div
                            className="col-lg-5 ml-auto"
                            data-aos="fade-left"
                            data-aos-duration="1200"
                        >
                            <div className="img-gallery">
                                <img src="images/genesis/certificate.jpeg" alt=""/>
                                <img
                                    src="images/shape/53.svg"
                                    alt="shape"
                                    className="shapes shape-one"
                                />
                                <img
                                    src="images/shape/52.svg"
                                    alt="shape"
                                    className="shapes shape-two"
                                />
                            </div>
                            {/* <!-- /.img-gallery --> */}
                        </div>
                        {/* End col-lg-5 */}
                    </div>
                </div>
            </div>
            {/* <!-- /.fancy-text-block-twelve --> */}
            <div className="fancy-short-banner-two mt-100">
                <div className="container">
                    <div className="content-wrapper">
                        <div
                            className="
                  bg-wrapper
                  d-lg-flex
                  align-items-center
                  justify-content-between
                "
                        >
                            <h2 className="font-gilroy-bold">
                                Залишились питання?
                            </h2>
                            <Link onClick={() => setIsOpen(true)}> Звʼязатись з нами </Link>
                            <MainModal title=" Залишились питання?" isOpen={isOpen} setIsOpen={setIsOpen}/>

                            <div className="bubble-one"></div>
                            <div className="bubble-two"></div>
                            <div className="bubble-three"></div>
                            <div className="bubble-four"></div>
                            <div className="bubble-five"></div>
                        </div>
                        {/* /.bg-wrapper */}
                    </div>
                    {/*  /.content-wrapper */}
                </div>
                {/* /.container */}
            </div>

            {/* =====================================================
            Footer Style Two
        ===================================================== */}
            <footer className="theme-footer-two pt-150 md-pt-80">
                <div className="top-footer">
                    <div className="container">
                        <FooterTwo/>
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRightTwo/>
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default ServiceV1;
