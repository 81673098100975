import React, {useState} from "react";
import {Link} from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import {
    ProSidebar,
    SidebarHeader,
    Menu,
    MenuItem,
    SubMenu,
    SidebarContent,
} from "react-pro-sidebar";

const ServiceMobileMenu = [
    {
        name: "Автоматизація",
        routerPath: "/service-details",
    },
    {
        name: "Аудит",
        routerPath: "/service-audit",
    },
    {
        name: "Навчання",
        routerPath: "/service-learning",
    },
    {
        name: "Впровадження",
        routerPath: "/service-implement",
    },
    {
        name: "Консалтинг",
        routerPath: "/service-consult",
    },
    {
        name: "Технічна підтримка",
        routerPath: "/support",
    },
];

const MegaMenuMobile = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    return (
        <div className="mega-menu-wrapper">
            <div className="mob-header multi-mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                    <div className={click ? "active" : ""}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
            </div>
            {/* End Header */}

            <ProSidebar
                className={click ? "mega-mobile-menu menu-open" : "mega-mobile-menu"}
            >
                <SidebarHeader>
                    <div className="logo position-static">
                        <a href="/">
                            <img src="/images/logo/main-logo.png" alt="home-demo"/>
                        </a>
                    </div>
                    <div className="fix-icon text-dark" onClick={handleClick}>
                        <img src="images/icon/close-w.svg" alt="icon"/>
                    </div>
                    {/* Mobile Menu close icon */}

                    {/* End logo */}
                </SidebarHeader>
                <SidebarContent>
                    <Menu>
                        <MenuItem>
                            <Link to="/">Головна</Link>
                        </MenuItem>

                        <SubMenu title="Послуги">
                            <MenuItem>
                                <a href='/service'>Всі послуги</a>
                            </MenuItem>
                            {ServiceMobileMenu.map((val, i) => (
                                <MenuItem key={i}>
                                    <Link to={val.routerPath}>{val.name}</Link>
                                </MenuItem>
                            ))}
                        </SubMenu>
                        {/* End Home SubMenu */}

                        <MenuItem>
                            <Link to="/creatio">Creatio</Link>
                        </MenuItem>

                        <MenuItem>
                            <Link to="/portfolio">
                                Портфоліо
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/blog">Блог</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/partner">Партнери</Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to="/about" >
                                Про нас
                            </Link>
                            {/* /.dropdown-menu */}
                        </MenuItem>

                        <MenuItem>
                            <Link to="/faq">
                                Хз
                            </Link>
                            {/* /.dropdown-menu */}
                        </MenuItem>
                        <MenuItem>
                            <Link to="/contact">
                                Контакти
                            </Link>
                            {/* /.dropdown-menu */}
                        </MenuItem>


                    </Menu>
                </SidebarContent>
            </ProSidebar>
        </div>
    );
};

export default MegaMenuMobile;
