import React, {useState} from "react";
import Brand from "../brand/Brand";
import {Link} from "react-router-dom";
import MainModal from "../modals";

const HeroBannerTwo = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  return (
    <div className="hero-banner-two">
      <div className="container">
        <div className="row align-items-start justify-content-between">
          <div className="col-lg-6 order-lg-last">
            <div className="illustration-holder">
              <img
                src="images/assets/ils_09.svg"
                alt="shape"
                className="illustration_01"
              />
              {/*<img*/}
              {/*  src="images/assets/ils_22.1.svg"*/}
              {/*  alt="shape"*/}
              {/*  className="shapes shape-one"*/}
              {/*/>*/}
              {/*<img*/}
              {/*  src="images/assets/ils-01-2.jpeg"*/}
              {/*  alt="shape"*/}
              {/*  className="shapes shape-two"*/}
              {/*/>*/}
              {/*<img*/}
              {/*  src="images/assets/ils-01-3.jpeg"*/}
              {/*  alt="shape"*/}
              {/*  className="shapes shape-three"*/}
              {/*/>*/}
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 order-lg-first">
            <div className="hero-text-wrapper md-mt-50">
              <h1>

                <span>
                  Впровадження CRM-систем
                  <img
                    src="images/shape/line-shape-1.svg"
                    alt="shape"
                    className="cs-screen"
                  />
                </span>
              </h1>
              <p className="sub-text">
                ✔️Автоматизація бізнес-процесів
                ✔️Аудит бізнес-процесів
                ✔️Навчання
                ✔️Консалтинг
                ✔️Технічна підтримка
              </p>
              <Link onClick={() => setIsOpen(true)}> <a className="theme-btn-two trial-button mt-50 md-mt-30" href="">Замовити консультацію</a> </Link>
              <MainModal title="Впровадження CRM-систем" isOpen={isOpen} setIsOpen={setIsOpen}/>
            </div>
            {/* /.hero-text-wrapper */}
          </div>
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}

    </div>
    // End .hero-banner-two
  );
};

export default HeroBannerTwo;
